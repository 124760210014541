import React from 'react';
import { Box, Container, Typography, Paper, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const Privacy = () => {
  const navigate = useNavigate();

  const privacyData = {
    title: "隐私政策",
    lastUpdated: "2024-03-19",
    content: [
      {
        section: "重要声明",
        content: "本站不保存任何视频，所有内容均来自互联网分享站点所提供的公开引用资源或采集资源，不提供资源上传\\下载。"
      },
      {
        section: "1. 信息收集",
        content: "我们收集的信息包括：\n- 基本账户信息（如电子邮件地址）\n- 看历史和偏好\n- 设备信息和IP地址\n- Cookies和类似技术收集的数据"
      },
      {
        section: "2. 信息使用",
        content: "我们使用收集的信息用于：\n- 提供和改进服务\n- 个性化内容推荐\n- 发送服务通知\n- 防止欺诈和滥用"
      },
      {
        section: "3. 信息共享",
        content: "我们不会出售您的个人信息。仅在以下情况下共享信息：\n- 经您同意\n- 遵守法律要求\n- 与服务提供商合作（需遵守保密义务）"
      },
      {
        section: "4. 信息安全",
        content: "我们采用行业标准的安全措施保护您的信息，包括：\n- 数据加密\n- 访问控制\n- 安全监控"
      },
      {
        section: "5. 您的权利",
        content: "您有权：\n- 访问您的个人信息\n- 更正不准确的信息\n- 删除您的账户\n- 选择退出个性化服务"
      },
      {
        section: "6. Cookie政策",
        content: "我们使用Cookie来：\n- 保持登录状态\n- 记住偏好设置\n- 分析使用情况\n- 提供个性化体验"
      },
      {
        section: "7. 儿童隐私",
        content: "我们的服务不面向13岁以下儿童。如发现误收集儿童信息，我们将立即删除。"
      },
      {
        section: "8. 政策更新",
        content: "我们可能更新本隐私政策。重大变更时将通知用户。继续使用我们的服务即表示您同意新的隐私政策。"
      }
    ]
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <IconButton 
        onClick={() => navigate(-1)} 
        sx={{ mb: 2 }}
        aria-label="返回"
      >
        <ArrowBackIcon />
      </IconButton>
      <Paper elevation={0} sx={{ p: 4, bgcolor: 'background.paper' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          {privacyData.title}
        </Typography>
        <Typography variant="body2" color="text.secondary" paragraph>
          最后更新：{privacyData.lastUpdated}
        </Typography>
        
        {privacyData.content.map((section, index) => (
          <Box key={index} sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom sx={{ color: 'primary.main' }}>
              {section.section}
            </Typography>
            <Typography
              variant="body1"
              paragraph
              sx={{ whiteSpace: 'pre-line' }}
            >
              {section.content}
            </Typography>
          </Box>
        ))}
      </Paper>
    </Container>
  );
};

export default Privacy; 