import React from 'react';
import { Box, Container, Typography, Paper, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const Terms = () => {
  const navigate = useNavigate();

  const termsData = {
    title: "服务条款",
    lastUpdated: "2024-03-19",
    content: [
      {
        section: "重要声明",
        content: "本站不保存任何视频，所有内容均来自互联网分享站点所提供的公开引用资源或采集资源，不提供资源上传\\下载。"
      },
      {
        section: "1. 服务说明",
        content: "247看提供在线视频流媒体服务，包括但不限于电影、电视剧、动漫等内容的在线观看。所有内容均来自第三方资源。"
      },
      {
        section: "2. 用户协议",
        content: "使用我们的服务即表示您同意：\n- 遵守所有适用法律\n- 不从事任何可能损害服务的行为\n- 不进行任何未经授权的访问或使用\n- 不进行任何形式的内容下载或分发"
      },
      {
        section: "3. 免责声明",
        content: "- 本站所有内容均来自第三方资源，不对内容的准确性、合法性负责\n- 不保证服务不中断或无错误\n- 对使用服务造成的损失不承担责任\n- 不对任何第三方内容的版权问题负责"
      },
      {
        section: "4. 内容使用",
        content: "- 仅供个人非商业使用\n- 严禁下载、复制或分发内容\n- 遵守版权和知识产权法律\n- 不得将内容用于任何商业目的"
      },
      {
        section: "5. 服务变更",
        content: "我们保留权利：\n- 随时修改或终止服务\n- 更改功能和界面\n- 限制服务访问\n- 删除任何违规内容"
      },
      {
        section: "6. 用户责任",
        content: "用户应：\n- 遵守本服务条款\n- 不传播非法或有害内容\n- 不从事任何侵犯他人权益的行为\n- 及时报告违规内容"
      },
      {
        section: "7. 终止条款",
        content: "我们可能因以下原因终止服务：\n- 违反服务条款\n- 非法使用\n- 滥用服务\n- 其他损害平台的行为"
      },
      {
        section: "8. 法律适用",
        content: "本条款受相关法律管辖。任何争议应通过友好协解决。"
      }
    ]
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <IconButton 
        onClick={() => navigate(-1)} 
        sx={{ mb: 2 }}
        aria-label="返回"
      >
        <ArrowBackIcon />
      </IconButton>
      <Paper elevation={0} sx={{ p: 4, bgcolor: 'background.paper' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          {termsData.title}
        </Typography>
        <Typography variant="body2" color="text.secondary" paragraph>
          最后更新：{termsData.lastUpdated}
        </Typography>
        
        {termsData.content.map((section, index) => (
          <Box key={index} sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom sx={{ color: 'primary.main' }}>
              {section.section}
            </Typography>
            <Typography
              variant="body1"
              paragraph
              sx={{ whiteSpace: 'pre-line' }}
            >
              {section.content}
            </Typography>
          </Box>
        ))}
      </Paper>
    </Container>
  );
};

export default Terms; 