import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  Paper,
  InputAdornment,
  IconButton
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useAuth } from '../contexts/AuthContext';
import { useLanguage } from '../contexts/LanguageContext';
import Layout from '../components/Layout';
import { alpha } from '@mui/material/styles';

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();
  const { t } = useLanguage();
  const [formData, setFormData] = useState({
    identifier: '',
    password: ''
  });
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(location.state?.message || '');
  const [turnstileToken, setTurnstileToken] = useState(null);
  const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';

  useEffect(() => {
    if (!isLocalhost) {
      const script = document.createElement('script');
      script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js';
      script.async = true;
      script.defer = true;

      script.onload = () => {
        if (window.turnstile) {
          window.turnstile.render('#login-turnstile', {
            sitekey: process.env.REACT_APP_TURNSTILE_SITE_KEY,
            callback: (token) => setTurnstileToken(token),
            theme: 'auto'
          });
        }
      };

      document.head.appendChild(script);

      return () => {
        if (window.turnstile) {
          window.turnstile.remove('#login-turnstile');
        }
        const existingScript = document.querySelector('script[src*="turnstile"]');
        if (existingScript) {
          document.head.removeChild(existingScript);
        }
      };
    }
  }, [isLocalhost]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    if (!isLocalhost && !turnstileToken) {
      setError(t('common', 'turnstileRequired'));
      setLoading(false);
      return;
    }

    try {
      await login(
        formData.identifier, 
        formData.password,
        isLocalhost ? 'localhost' : turnstileToken
      );
      const redirectTo = location.state?.from?.pathname || '/member';
      navigate(redirectTo);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <Container component="main" maxWidth="xs" sx={{ px: { xs: 2, sm: 0 }, py: { xs: 8, sm: 10 } }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: '70vh'
          }}
        >
          <Paper
            elevation={3}
            sx={{
              p: { xs: 2, sm: 4 },
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Typography 
              component="h1" 
              variant="h5" 
              sx={{ 
                mb: 3,
                fontSize: { xs: '1.25rem', sm: '1.5rem' }
              }}
            >
              {t('login', 'title')}
            </Typography>

            {successMessage && (
              <Alert severity="success" sx={{ width: '100%', mb: 2 }}>
                {successMessage}
              </Alert>
            )}

            {error && (
              <Alert severity="error" sx={{ width: '100%', mb: 2 }}>
                {error}
              </Alert>
            )}

            <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="identifier"
                label={t('login', 'usernameOrEmail')}
                name="identifier"
                autoComplete="username email"
                autoFocus
                value={formData.identifier}
                onChange={handleChange}
                placeholder={t('login', 'usernameOrEmailPlaceholder')}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label={t('login', 'password')}
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                value={formData.password}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {!isLocalhost && (
                <div
                  id="login-turnstile"
                  style={{ 
                    marginTop: '1rem',
                    marginBottom: '1rem',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                />
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={loading || (!isLocalhost && !turnstileToken)}
              >
                {loading ? t('common', 'loading') : t('login', 'submit')}
              </Button>
            </Box>
          </Paper>

          <Paper
            elevation={2}
            sx={{
              p: { xs: 2, sm: 3 },
              mt: 2,
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              bgcolor: theme => alpha(theme.palette.primary.main, 0.05)
            }}
          >
            <Typography variant="body1" align="center" gutterBottom>
              {t('login', 'noAccount')}
            </Typography>
            <Button
              component={RouterLink}
              to="/register"
              variant="outlined"
              fullWidth
              sx={{ mt: 1 }}
            >
              {t('login', 'register')}
            </Button>
          </Paper>
        </Box>
      </Container>
    </Layout>
  );
};

export default Login; 