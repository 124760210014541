import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Chip,
  Stack,
  CircularProgress,
  Paper,
  useTheme,
  Pagination,
  useMediaQuery,
  Alert,
  IconButton,
  Tooltip,
  Button,
  Skeleton,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import FilterListIcon from '@mui/icons-material/FilterList';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ContentCard from '../components/ContentCard';
import MovieDetail from './MovieDetail';
import Layout from '../components/Layout';
import { useLanguage } from '../contexts/LanguageContext';
import { searchVideos } from '../services/api';
import StarIcon from '@mui/icons-material/Star';
import PersonIcon from '@mui/icons-material/Person';
import LocalMoviesIcon from '@mui/icons-material/LocalMovies';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TranslateIcon from '@mui/icons-material/Translate';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ChatIcon from '@mui/icons-material/Chat';

const Search = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useLanguage();
  
  const [searchQuery, setSearchQuery] = useState('');
  const [displayQuery, setDisplayQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('All');
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [viewMode, setViewMode] = useState('grid');
  const itemsPerPage = 20;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const query = params.get('q');
    const pageParam = parseInt(params.get('page')) || 1;
    if (query) {
      setSearchQuery(query);
      setDisplayQuery(query);
      setPage(pageParam);
      performSearch(query, pageParam);
    }
  }, [location.search]);

  const performSearch = async (query, currentPage = 1) => {
    if (!query.trim()) return;
    
    setIsLoading(true);
    setError(null);
    try {
      const data = await searchVideos(query, currentPage, itemsPerPage);
      const mappedResults = (data.videos || []).map(video => ({
        id: video.vod_id,
        title: video.vod_name,
        imageUrl: video.vod_pic,
        rating: video.vod_douban_score ? Number(video.vod_douban_score) : 0,
        year: video.vod_year,
        remarks: video.vod_remarks,
        vod_id: video.vod_id,
        vod_name: video.vod_name,
        vod_pic: video.vod_pic,
        vod_douban_score: video.vod_douban_score ? Number(video.vod_douban_score) : 0,
        vod_year: video.vod_year,
        vod_remarks: video.vod_remarks,
        vod_actor: video.vod_actor,
        vod_director: video.vod_director,
        vod_area: video.vod_area,
        vod_lang: video.vod_lang,
        vod_content: video.vod_content,
        vod_class: video.vod_class,
        tmdb_id: video.tmdb_id,
        tmdb_rating: video.tmdb_rating,
        tmdb_votes: video.tmdb_votes,
        tmdb_poster: video.tmdb_poster,
        tmdb_backdrop: video.tmdb_backdrop,
        original_title: video.original_title,
        alternative_titles: video.alternative_titles,
        overview: video.overview
      }));
      setResults(mappedResults);
      setTotalPages(Math.ceil(data.total / itemsPerPage));
    } catch (err) {
      console.error('Error searching videos:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (e) => {
    if (e.key === 'Enter' && searchQuery.trim()) {
      setDisplayQuery(searchQuery.trim());
      navigate(`/search?q=${encodeURIComponent(searchQuery.trim())}&page=1`);
    }
  };

  const handleClear = () => {
    setSearchQuery('');
    setDisplayQuery('');
    setResults([]);
    setError(null);
    navigate('/search');
  };

  const handlePageChange = (_, newPage) => {
    const params = new URLSearchParams(location.search);
    params.set('page', newPage.toString());
    navigate(`/search?${params.toString()}`);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const filterTags = [
    { id: 'All', label: t('search', 'all') },
    { id: 'Movies', label: t('search', 'movies') },
    { id: 'TVShows', label: t('search', 'tvShows') },
    { id: 'Anime', label: t('search', 'anime') },
    { id: 'Recent', label: t('search', 'recentlyAdded') },
  ];

  const renderListItem = (video) => {
    const score = parseFloat(video.vod_douban_score || 0);
    const matchesActor = video.vod_actor && video.vod_actor.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesDirector = video.vod_director && video.vod_director.toLowerCase().includes(searchQuery.toLowerCase());

    return (
      <Link 
        to={`/detail/${video.vod_id}`}
        style={{ textDecoration: 'none', width: '100%' }}
      >
        <Paper
          elevation={1}
          sx={{
            display: 'flex',
            p: { xs: 1.5, sm: 2 },
            gap: { xs: 1.5, sm: 2 },
            height: '100%',
            '&:hover': {
              boxShadow: (theme) => theme.shadows[4],
              transform: 'translateY(-2px)',
              transition: 'all 0.3s ease',
            },
          }}
        >
          {/* Thumbnail */}
          <Box
            sx={{
              position: 'relative',
              flexShrink: 0,
              width: { xs: 120, sm: 160 },
              height: { xs: 160, sm: 200 },
            }}
          >
            <img
              src={video.vod_pic}
              alt={video.vod_name}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: 8,
              }}
            />
            {score > 0 && (
              <Chip
                label={score.toFixed(1)}
                size="small"
                color="primary"
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  bgcolor: 'rgba(0, 0, 0, 0.7)',
                  '& .MuiChip-label': {
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: { xs: '0.875rem', sm: '1rem' },
                  },
                }}
              />
            )}
          </Box>

          {/* Content */}
          <Box sx={{ 
            flex: 1, 
            display: 'flex', 
            flexDirection: 'column', 
            minHeight: { xs: 160, sm: 200 },
            overflow: 'hidden'
          }}>
            <Typography 
              variant="h6" 
              sx={{ 
                mb: 0.5,
                fontSize: { xs: '1.25rem', sm: '1.5rem' },
                fontWeight: 500,
                color: 'text.primary',
                display: '-webkit-box',
                WebkitLineClamp: 1,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                lineHeight: 1.2,
              }}
            >
              {video.vod_name}
            </Typography>

            {/* Actor and Director info */}
            <Stack spacing={0.5} sx={{ mb: 1 }}>
              {video.vod_actor && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <PersonIcon color="action" sx={{ fontSize: '1.2rem' }} />
                  <Typography 
                    variant="body2" 
                    color={video.vod_actor.toLowerCase().includes(searchQuery.toLowerCase()) ? "primary" : "text.secondary"}
                    sx={{ 
                      display: '-webkit-box',
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      fontSize: { xs: '0.875rem', sm: '1rem' },
                      fontWeight: video.vod_actor.toLowerCase().includes(searchQuery.toLowerCase()) ? 500 : 400,
                    }}
                  >
                    演员：{video.vod_actor.split(',').slice(0, 3).join(', ')}
                  </Typography>
                </Box>
              )}
              {video.vod_director && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <LocalMoviesIcon color="action" sx={{ fontSize: '1.2rem' }} />
                  <Typography 
                    variant="body2" 
                    color={video.vod_director.toLowerCase().includes(searchQuery.toLowerCase()) ? "primary" : "text.secondary"}
                    sx={{ 
                      display: '-webkit-box',
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      fontSize: { xs: '0.875rem', sm: '1rem' },
                      fontWeight: video.vod_director.toLowerCase().includes(searchQuery.toLowerCase()) ? 500 : 400,
                    }}
                  >
                    导演：{video.vod_director.split(',').slice(0, 2).join(', ')}
                  </Typography>
                </Box>
              )}
            </Stack>

            {/* Description */}
            {video.vod_content && (
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ 
                  display: '-webkit-box',
                  WebkitLineClamp: { xs: 2, sm: 2 },
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  fontSize: { xs: '0.875rem', sm: '1rem' },
                  mb: 1,
                  flex: 1,
                }}
              >
                {video.vod_content}
              </Typography>
            )}

            {/* Bottom info section */}
            <Box sx={{ mt: 'auto' }}>
              <Stack 
                direction="row" 
                spacing={0.5} 
                flexWrap="wrap" 
                sx={{ gap: 0.5, mb: 0.5 }}
              >
                {video.vod_year && (
                  <Chip
                    label={video.vod_year}
                    size="small"
                    variant="outlined"
                    sx={{ 
                      borderRadius: 1,
                      height: 24,
                      '& .MuiChip-label': {
                        px: 1,
                        fontSize: { xs: '0.875rem', sm: '1rem' },
                      },
                    }}
                  />
                )}
                {video.vod_area && (
                  <Chip
                    label={video.vod_area}
                    size="small"
                    variant="outlined"
                    sx={{ 
                      borderRadius: 1,
                      height: 24,
                      '& .MuiChip-label': {
                        px: 1,
                        fontSize: { xs: '0.875rem', sm: '1rem' },
                      },
                    }}
                  />
                )}
                {video.type_name && (
                  <Chip
                    label={video.type_name}
                    size="small"
                    variant="outlined"
                    sx={{ 
                      borderRadius: 1,
                      height: 24,
                      '& .MuiChip-label': {
                        px: 1,
                        fontSize: { xs: '0.875rem', sm: '1rem' },
                      },
                    }}
                  />
                )}
              </Stack>

              {video.vod_remarks && (
                <Typography 
                  variant="body2" 
                  color="primary"
                  sx={{ 
                    fontSize: { xs: '0.875rem', sm: '1rem' },
                    fontWeight: 500,
                  }}
                >
                  {video.vod_remarks}
                </Typography>
              )}
            </Box>
          </Box>
        </Paper>
      </Link>
    );
  };

  const renderGridItem = (video) => {
    const score = parseFloat(video.vod_douban_score || 0);

    return (
      <Link 
        to={`/detail/${video.vod_id}`}
        style={{ textDecoration: 'none', width: '100%', height: '100%' }}
      >
        <Paper
          elevation={1}
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            '&:hover': {
              boxShadow: (theme) => theme.shadows[4],
              transform: 'translateY(-2px)',
              transition: 'all 0.3s ease',
            },
          }}
        >
          <Box sx={{ position: 'relative', pt: '140%' }}>
            <Box
              component="img"
              src={video.vod_pic}
              alt={video.vod_name}
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '4px 4px 0 0',
              }}
            />
            {score > 0 && (
              <Chip
                label={score.toFixed(1)}
                size="small"
                color="primary"
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  bgcolor: 'rgba(0, 0, 0, 0.7)',
                  '& .MuiChip-label': {
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: { xs: '0.875rem', sm: '1rem' },
                    py: 0.5,
                  },
                }}
              />
            )}
          </Box>

          <Box sx={{ 
            p: { xs: 1.5, sm: 2 }, 
            flexGrow: 1, 
            display: 'flex', 
            flexDirection: 'column',
            height: { xs: '180px', sm: '200px' },
          }}>
            <Typography
              variant="h6"
              sx={{
                mb: 1,
                fontWeight: 500,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                lineHeight: '1.3',
                fontSize: { xs: '1.1rem', sm: '1.25rem' },
                color: 'text.primary',
              }}
            >
              {video.vod_name}
            </Typography>

            {/* Content description */}
            {video.vod_content && (
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ 
                  display: '-webkit-box',
                  WebkitLineClamp: { xs: 3, sm: 3 },
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  fontSize: { xs: '0.9rem', sm: '1rem' },
                  lineHeight: '1.4',
                  mb: 1,
                  flex: 1,
                  opacity: 0.9,
                }}
              >
                {video.vod_content}
              </Typography>
            )}

            <Box sx={{ mt: 'auto' }}>
              {video.vod_remarks && (
                <Typography 
                  variant="body2" 
                  color="primary"
                  sx={{ 
                    display: 'block',
                    mb: 0.5,
                    fontSize: { xs: '0.9rem', sm: '1rem' },
                    fontWeight: 500,
                  }}
                >
                  {video.vod_remarks}
                </Typography>
              )}

              <Stack 
                direction="row" 
                spacing={0.5} 
                flexWrap="wrap" 
                sx={{ gap: 0.5 }}
              >
                {video.vod_year && (
                  <Chip
                    label={video.vod_year}
                    size="small"
                    variant="outlined"
                    sx={{ 
                      borderRadius: 1,
                      height: { xs: 26, sm: 28 },
                      '& .MuiChip-label': {
                        px: 1,
                        fontSize: { xs: '0.9rem', sm: '1rem' },
                        py: 0.5,
                      },
                    }}
                  />
                )}
                {video.type_name && (
                  <Chip
                    label={video.type_name}
                    size="small"
                    variant="outlined"
                    sx={{ 
                      borderRadius: 1,
                      height: { xs: 26, sm: 28 },
                      '& .MuiChip-label': {
                        px: 1,
                        fontSize: { xs: '0.9rem', sm: '1rem' },
                        py: 0.5,
                      },
                    }}
                  />
                )}
              </Stack>
            </Box>
          </Box>
        </Paper>
      </Link>
    );
  };

  return (
    <Layout>
      <Helmet>
        <title>{displayQuery ? `${displayQuery} - 搜索结果 | 247看` : '搜索影视剧 | 247看 - 免费在线观看'}</title>
        <meta name="description" content="247看搜索页面 - 海量高清影视资源，免费在线观看。提供电影、电视剧、动漫、综艺等各类影视作品，1080P蓝光画质，无广告播放。支持手机、平板等多设备观看。" />
        <meta name="keywords" content="247看,在线影视,免费观看,高清电影,连续剧在线,动漫视频,综艺节目,纪录片,短剧,剧集,1080P高清,蓝光画质,无广告播放,手机观看,追剧,追番,最新电影,热播剧集,热门动漫,国产剧,韩剧,日剧,美剧,港剧,台剧,泰剧,在线串流,极速播放,多线路" />
        <meta property="og:title" content={displayQuery ? `${displayQuery} - 搜索结果 | 247看` : '搜索影视剧 | 247看 - 免费在线观看'} />
        <meta property="og:description" content="247看搜索页面 - 海量高清影视资源，免费在线观看。提供电影、电视剧、动漫、综艺等各类影视作品，1080P蓝光画质，无广告播放。" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={`https://247kan.com/search${displayQuery ? `?q=${encodeURIComponent(displayQuery)}` : ''}`} />
      </Helmet>
      <Container 
        maxWidth="xl" 
        sx={{ 
          py: 4,
          mt: { xs: 7, sm: 8 },
        }}
      >
        {/* Search Header */}
        <Box sx={{ mb: 4, display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography variant="h4" sx={{ flexGrow: 1 }}>
            {t('search', 'searchTitle')}
          </Typography>
          <Tooltip title={t('search', 'toggleView')}>
            <IconButton onClick={() => setViewMode(prev => prev === 'grid' ? 'list' : 'grid')}>
              {viewMode === 'grid' ? <ViewListIcon /> : <ViewModuleIcon />}
            </IconButton>
          </Tooltip>
        </Box>

        {/* Search Input */}
        <Paper 
          elevation={0} 
          sx={{ 
            p: 2, 
            mb: 4,
            bgcolor: theme.palette.mode === 'dark' ? 'background.paper' : 'grey.50',
            border: 1,
            borderColor: 'divider',
          }}
        >
          <Box sx={{ display: 'flex', gap: 2 }}>
            <TextField
              fullWidth
              variant="outlined"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyPress={handleSearch}
              placeholder={t('search', 'searchPlaceholder')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                ),
                endAdornment: searchQuery && (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={handleClear}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  bgcolor: 'background.paper',
                },
              }}
            />
            <Button
              variant="contained"
              onClick={() => searchQuery.trim() && navigate(`/search?q=${encodeURIComponent(searchQuery.trim())}&page=1`)}
              startIcon={<SearchIcon />}
            >
              {t('search', 'search')}
            </Button>
          </Box>
        </Paper>

        {/* Results */}
        {isLoading ? (
          <Box sx={{ width: '100%' }}>
            <Box sx={{ mb: 3, display: 'flex', alignItems: 'center', gap: 1 }}>
              <Skeleton variant="text" width={120} height={32} />
              <Skeleton variant="text" width={100} height={32} />
              <Skeleton variant="text" width={80} height={32} sx={{ ml: 1 }} />
            </Box>
            <Grid container spacing={2}>
              {[...Array(viewMode === 'list' ? 5 : 10)].map((_, index) => (
                <Grid 
                  item 
                  xs={6}
                  sm={viewMode === 'list' ? 12 : 6} 
                  md={viewMode === 'list' ? 12 : 4} 
                  lg={viewMode === 'list' ? 12 : 2.4}
                  key={index}
                >
                  {viewMode === 'list' ? (
                    <Paper elevation={1} sx={{ p: 2 }}>
                      <Box sx={{ display: 'flex', gap: 2 }}>
                        <Skeleton variant="rectangular" width={120} height={180} sx={{ borderRadius: 1 }} />
                        <Box sx={{ flex: 1 }}>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                            <Box sx={{ width: '70%' }}>
                              <Skeleton variant="text" width="90%" height={32} />
                              <Skeleton variant="text" width="60%" height={24} />
                            </Box>
                            <Stack direction="row" spacing={1}>
                              <Skeleton variant="rectangular" width={60} height={24} sx={{ borderRadius: 16 }} />
                              <Skeleton variant="rectangular" width={60} height={24} sx={{ borderRadius: 16 }} />
                            </Stack>
                          </Box>
                          <Skeleton variant="text" width="100%" />
                          <Skeleton variant="text" width="90%" />
                          <Skeleton variant="text" width="95%" />
                          <Box sx={{ mt: 2 }}>
                            <Stack direction="row" spacing={1}>
                              <Skeleton variant="rectangular" width={100} height={32} sx={{ borderRadius: 1 }} />
                              <Skeleton variant="rectangular" width={100} height={32} sx={{ borderRadius: 1 }} />
                            </Stack>
                          </Box>
                        </Box>
                      </Box>
                    </Paper>
                  ) : (
                    <Paper elevation={1} sx={{ height: '100%', p: 1 }}>
                      <Skeleton variant="rectangular" width="100%" height={240} sx={{ borderRadius: 1, mb: 1 }} />
                      <Skeleton variant="text" width="80%" height={24} />
                      <Skeleton variant="text" width="60%" height={20} />
                      <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Skeleton variant="rectangular" width={60} height={24} sx={{ borderRadius: 16 }} />
                        <Skeleton variant="rectangular" width={40} height={24} sx={{ borderRadius: 16 }} />
                      </Box>
                    </Paper>
                  )}
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : error ? (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        ) : results.length > 0 ? (
          <>
            <Box sx={{ mb: 3, display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="h6">
                {t('search', 'resultsFor')}
              </Typography>
              <Typography variant="h6" color="primary">
                "{displayQuery}"
              </Typography>
              <Typography variant="h6" color="text.secondary" sx={{ ml: 1 }}>
                ({results.length} {t('search', 'results')})
              </Typography>
            </Box>
            <Grid 
              container 
              spacing={viewMode === 'grid' ? { xs: 1, sm: 2 } : 1}
              sx={{
                mx: viewMode === 'grid' ? { xs: -1, sm: 0 } : 0,
                mt: viewMode === 'grid' ? { xs: 1, sm: 2 } : 1
              }}
            >
              {results.map((video) => (
                <Grid 
                  item 
                  xs={viewMode === 'grid' ? 6 : 12} 
                  sm={viewMode === 'grid' ? 4 : 12} 
                  md={viewMode === 'grid' ? 3 : 12} 
                  lg={viewMode === 'grid' ? 2.4 : 12} 
                  key={video.vod_id}
                  sx={{ 
                    px: viewMode === 'grid' ? { xs: 0.25, sm: 1 } : 1,
                    mb: viewMode === 'grid' ? { xs: 0.5, sm: 2 } : 1
                  }}
                >
                  {viewMode === 'grid' ? renderGridItem(video) : renderListItem(video)}
                </Grid>
              ))}
            </Grid>

            {/* Pagination */}
            {totalPages > 1 && (
              <Box 
                sx={{ 
                  display: 'flex', 
                  justifyContent: 'center', 
                  mt: 4,
                  mb: 2,
                  '& .MuiPagination-ul': {
                    flexWrap: 'nowrap',
                  }
                }}
              >
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                  size={isMobile ? "small" : "medium"}
                  showFirstButton
                  showLastButton
                  siblingCount={isMobile ? 0 : 1}
                  boundaryCount={isMobile ? 1 : 2}
                />
              </Box>
            )}
          </>
        ) : searchQuery ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '300px',
              gap: 2,
            }}
          >
            <Typography variant="h6" color="text.secondary" align="center">
              {t('search', 'noResults')} "{searchQuery}"
            </Typography>
            <Typography variant="body1" color="text.secondary" align="center">
              {t('search', 'tryDifferentSearch')}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={`/guestbook?content=${encodeURIComponent(`您好，我在搜索"${searchQuery}"但未找到相关内容。希望能添加这部作品，谢谢！`)}`}
              startIcon={<ChatIcon />}
              sx={{ mt: 2 }}
            >
              {t('search', 'requestContent')}
            </Button>
          </Box>
        ) : null}
      </Container>
    </Layout>
  );
};

export default Search; 