import React from 'react';
import { Grid, Button, useMediaQuery, useTheme } from '@mui/material';

const EpisodeSelector = ({
  episodes = [],
  selectedEpisode,
  selectedRoute,
  onEpisodeSelect,
  video
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (!episodes?.length) return null;

  const currentRoute = video?.available_routes?.[selectedRoute];
  if (!currentRoute) return null;

  const routeEpisodes = episodes.filter(ep => ep.route === currentRoute);
  if (!routeEpisodes.length) return null;

  return (
    <Grid container spacing={1}>
      {routeEpisodes.map((episode) => (
        <Grid item key={episode.episode}>
          <Button
            variant={selectedEpisode === episode.episode ? "contained" : "outlined"}
            onClick={() => onEpisodeSelect(episode.episode)}
            sx={{
              minWidth: 'auto',
              height: isMobile ? 45 : 56,
              borderRadius: 1,
              px: 2,
            }}
          >
            {episode.name || `第${episode.episode}集`}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};

export default EpisodeSelector; 