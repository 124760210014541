import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Stack,
  Typography,
  TextField,
  Button,
  Card,
  CircularProgress,
  Alert,
  Snackbar,
  Container
} from '@mui/material';
import { useLanguage } from '../../contexts/LanguageContext';
import { useAuth } from '../../contexts/AuthContext';
import { getGuestbookEntries, addGuestbookEntry } from '../../services/api';
import Layout from '../Layout';
import Cookies from 'js-cookie';
import { generateAnonymousName } from '../../utils/nameGenerator';
import { useLocation } from 'react-router-dom';

const Guestbook = () => {
  const { t } = useLanguage();
  const { user } = useAuth();
  const [entries, setEntries] = useState([]);
  const [content, setContent] = useState('');
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(null);
  const [showError, setShowError] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [codeId, setCodeId] = useState(null);
  const [captchaSvg, setCaptchaSvg] = useState('');
  const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
  const [anonymousName, setAnonymousName] = useState('');
  const location = useLocation();
  const [totalEntries, setTotalEntries] = useState(0);
  const ITEMS_PER_PAGE = 10;

  useEffect(() => {
    // Only set anonymous name if user is not logged in
    if (!user) {
      let name = Cookies.get('anonymousName');
      if (!name) {
        name = generateAnonymousName();
        Cookies.set('anonymousName', name, { 
          expires: 30,
          path: '/',
          sameSite: 'Lax'
        });
      }
      setAnonymousName(name);
    }
  }, [user]);

  const fetchEntries = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getGuestbookEntries(page, ITEMS_PER_PAGE);
      setEntries(data.entries);
      setHasMore(data.total > page * ITEMS_PER_PAGE);
      setTotalEntries(data.total);
    } catch (error) {
      console.error('Failed to fetch guestbook entries:', error);
      setError(error.message);
      setShowError(true);
    } finally {
      setLoading(false);
    }
  }, [page]);

  const fetchVerificationCode = useCallback(async () => {
    try {
      const response = await fetch('/api/verification-code');
      const data = await response.json();
      if (data.status === 'success') {
        setCodeId(data.data.codeId);
        setCaptchaSvg(data.data.svgData);
      }
    } catch (error) {
      console.error('Failed to fetch verification code:', error);
      setError(t('watch', 'verificationCodeError'));
      setShowError(true);
    }
  }, [t]);

  useEffect(() => {
    fetchEntries();
    // Get content from URL parameter if it exists
    const params = new URLSearchParams(location.search);
    const urlContent = params.get('content');
    if (urlContent) {
      setContent(decodeURIComponent(urlContent));
    }
  }, [location.search, page, fetchEntries]);

  useEffect(() => {
    // Always fetch verification code
    fetchVerificationCode();
  }, [fetchVerificationCode]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!content.trim()) return;

    try {
      setLoading(true);
      if (!verificationCode) {
        setError(t('watch', 'invalidVerificationCode'));
        setShowError(true);
        return;
      }

      console.log('User object:', user);
      console.log('User ID being sent:', user ? user.user_id : null);

      await addGuestbookEntry(
        content,
        codeId,
        verificationCode,
        user ? user.username : anonymousName,
        user ? user.user_id : null
      );
      
      setContent('');
      setVerificationCode('');
      // Get new verification code
      fetchVerificationCode();
      // Refresh entries
      setPage(1);
      await fetchEntries();
    } catch (error) {
      console.error('Failed to add guestbook entry:', error);
      setError(error.response?.data?.details || error.message || t('common', 'guestbookError'));
      setShowError(true);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    window.scrollTo(0, 0);
  };

  const getPageNumbers = () => {
    const totalPages = Math.ceil(totalEntries / ITEMS_PER_PAGE);
    const current = page;
    const pages = [];
    
    // Always show first page
    pages.push(1);
    
    // Calculate range of pages to show
    let start = Math.max(2, current - 2);
    let end = Math.min(totalPages - 1, current + 2);
    
    // Add ellipsis after first page if needed
    if (start > 2) {
      pages.push('...');
    }
    
    // Add pages in range
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
    
    // Add ellipsis before last page if needed
    if (end < totalPages - 1) {
      pages.push('...');
    }
    
    // Add last page if there is more than one page
    if (totalPages > 1) {
      pages.push(totalPages);
    }
    
    return pages;
  };

  return (
    <Layout>
      <Container maxWidth="lg" sx={{ mt: { xs: 8, sm: 10 }, mb: 4 }}>
        <Box sx={{ maxWidth: 800, mx: 'auto' }}>
          <Snackbar
            open={showError}
            autoHideDuration={6000}
            onClose={() => setShowError(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert severity="error" variant="filled" onClose={() => setShowError(false)}>
              {error}
            </Alert>
          </Snackbar>

          <Typography variant="h4" gutterBottom align="center" sx={{ mb: 4 }}>
            {t('guestbook', 'title')}
          </Typography>

          <Box component="form" onSubmit={handleSubmit} sx={{ mb: 4 }}>
            <TextField
              fullWidth
              multiline
              rows={3}
              value={content}
              onChange={(e) => setContent(e.target.value)}
              placeholder={t('guestbook', 'placeholder')}
              sx={{ mb: 2 }}
            />
            <Box sx={{ mb: 2 }}>
              <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1,
                mb: 1 
              }}>
                <div 
                  dangerouslySetInnerHTML={{ __html: captchaSvg }}
                  onClick={fetchVerificationCode}
                  style={{ cursor: 'pointer' }}
                  title={t('watch', 'clickToRefresh')}
                />
              </Box>
              <TextField
                fullWidth
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                placeholder={t('watch', 'enterVerificationCode')}
                size="small"
              />
            </Box>
            <Button 
              variant="contained" 
              type="submit"
              disabled={!content.trim() || !verificationCode || loading}
              sx={{ mt: 1 }}
            >
              {loading ? t('guestbook', 'submitting') : t('guestbook', 'submit')}
            </Button>
          </Box>

          {entries.length === 0 ? (
            <Box sx={{ textAlign: 'center', py: 4, color: 'text.secondary' }}>
              <Typography>
                {t('guestbook', 'noEntries')}
              </Typography>
            </Box>
          ) : (
            <>
              <Stack spacing={2}>
                {entries.map((entry) => (
                  <Card key={entry.gbook_id} sx={{ p: 2 }}>
                    <Stack spacing={1}>
                      <Stack 
                        direction="row" 
                        spacing={2} 
                        alignItems="center"
                        sx={{ color: 'text.secondary' }}
                      >
                        <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                          {entry.gbook_name || t('common', 'anonymous')}
                        </Typography>
                        <Typography variant="caption">
                          {new Date(entry.gbook_time * 1000).toLocaleString()}
                        </Typography>
                      </Stack>
                      <Typography variant="body1">
                        {entry.gbook_content}
                      </Typography>
                      {entry.gbook_reply && (
                        <Box 
                          sx={{ 
                            ml: 4, 
                            p: 2, 
                            bgcolor: 'action.hover',
                            borderRadius: 1
                          }}
                        >
                          <Typography 
                            variant="body2" 
                            sx={{ 
                              color: 'text.secondary',
                              '&::before': {
                                content: '""',
                                fontWeight: 'bold'
                              }
                            }}
                          >
                            <strong>{t('guestbook', 'adminReply')}:</strong> {entry.gbook_reply}
                          </Typography>
                          {entry.gbook_reply_time && (
                            <Typography variant="caption" sx={{ color: 'text.secondary', mt: 1, display: 'block' }}>
                              {new Date(entry.gbook_reply_time * 1000).toLocaleString()}
                            </Typography>
                          )}
                        </Box>
                      )}
                    </Stack>
                  </Card>
                ))}
              </Stack>

              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                <Stack spacing={1} direction="row" alignItems="center">
                  <Button
                    variant="outlined"
                    onClick={() => handlePageChange(page - 1)}
                    disabled={page === 1 || loading}
                    size="small"
                  >
                    {t('common', 'previous')}
                  </Button>
                  
                  {getPageNumbers().map((pageNum, index) => (
                    <React.Fragment key={index}>
                      {pageNum === '...' ? (
                        <Typography variant="body2" sx={{ mx: 1 }}>
                          {pageNum}
                        </Typography>
                      ) : (
                        <Button
                          variant={pageNum === page ? "contained" : "outlined"}
                          onClick={() => handlePageChange(pageNum)}
                          disabled={loading}
                          size="small"
                          sx={{
                            minWidth: '36px',
                            px: 1
                          }}
                        >
                          {pageNum}
                        </Button>
                      )}
                    </React.Fragment>
                  ))}

                  <Button
                    variant="outlined"
                    onClick={() => handlePageChange(page + 1)}
                    disabled={page >= Math.ceil(totalEntries / ITEMS_PER_PAGE) || loading}
                    size="small"
                  >
                    {t('common', 'next')}
                  </Button>
                </Stack>
              </Box>
            </>
          )}
        </Box>
      </Container>
    </Layout>
  );
};

export default Guestbook; 