import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Container,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Chip,
  CircularProgress,
  useTheme,
  Tabs,
  Tab,
  Paper,
  Drawer,
  IconButton,
  useMediaQuery,
  Fade,
  Skeleton,
  Alert,
  Button,
  Tooltip,
  Divider,
  Badge,
  Pagination,
  Breadcrumbs,
  Link,
  Card,
  CardContent,
  CardMedia,
  TextField,
  InputAdornment,
  ToggleButton,
  ToggleButtonGroup,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { useLanguage } from '../contexts/LanguageContext';
import Layout from '../components/Layout';
import ContentCard from '../components/ContentCard';
import FilterListIcon from '@mui/icons-material/FilterList';
import SortIcon from '@mui/icons-material/Sort';
import CloseIcon from '@mui/icons-material/Close';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import StarIcon from '@mui/icons-material/Star';
import UpdateIcon from '@mui/icons-material/Update';
import { getTypes, getVideosByCategory, getCategories, searchVideos, getGenresByCategory } from '../services/api';
import { alpha } from '@mui/material/styles';
import MovieDetail from '../pages/MovieDetail';
import debounce from 'lodash/debounce';
import ContentDiscovery from '../components/ContentDiscovery';
import ExploreIcon from '@mui/icons-material/Explore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

// Define translations with fallbacks
const translations = {
  filters: {
    title: '影视筛选 - 高清电影、连续剧、动漫、综艺节目在线观看',
    area: '地区',
    language: '语言',
    year: '年份',
    version: '版本',
    sortBy: '排序',
    clearAll: '清除所有筛选',
    search: '搜索',
    actor: '演员',
    director: '导演',
    state: '类型',
    letter: '字母',
    tag: '标签',
    genre: '类型',
    trending: '热门内容',
    top250: '豆瓣Top 250',
    all: '全部',
    quickFilters: '快速筛选',
    basicFilters: '基本筛选',
    advancedFilters: '高级筛选',
    sortOptions: '排序选项',
    clearAllFilters: '清除所有筛选',
    filterCount: '筛选',
    gridView: '网格视图',
    listView: '列表视图',
    resultsCount: '${count}个结果'
  },
  browse: {
    all: '全部影视',
    latest: '最新上线',
    mostViewed: '热播榜单',
    highestRated: '豆瓣电影Top 250',
    noContent: '暂无内容',
    noVideosFound: '没有找到相关影视',
    tryDifferentFilters: '请尝试其他筛选条件',
    allContent: '全部内容',
    clearFilters: '清除筛选',
    byYear: '按年份',
    results: '个结果',
    metaTitle: '247看 - 免费在线观看高清电影、电视剧、动漫、综艺',
    metaDescription: '海量高清影视资源，1080P蓝光画质，支持手机观看，无广告，免费在线观看，实时更新，国产剧、韩剧、美剧、日剧、港剧、台剧应有尽有',
    metaKeywords: '在线影视,高清电影,连续剧在线,动漫视频,综艺节目,纪录片,短剧,剧集,1080P高清,蓝光画质,无广告播放,免费观看,在线播放,直接观看,无需下载,手机观看,免费高清,追剧,追番,最新电影,热播剧集,热门动漫,新番动漫,热映电影,同步更新,实时更新',
    categories: {
      movie: '电影',
      drama: '电视剧',
      anime: '动漫',
      variety: '综艺',
      documentary: '纪录片'
    },
    features: {
      hd: '1080P高清',
      bluray: '蓝光画质',
      noAds: '无广告',
      free: '免费观看',
      mobile: '手机观看',
      update: '实时更新'
    }
  },
  errors: {
    failedToLoadVideos: '加载视频失败，请稍后重试'
  }
};

const LoadingSkeleton = ({ viewMode = 'grid', isMobile }) => (
  <Grid container spacing={2}>
    {[...Array(12)].map((_, index) => (
      <Grid 
        item 
        xs={viewMode === 'list' ? 12 : 6} 
        sm={viewMode === 'list' ? 12 : 6} 
        md={viewMode === 'list' ? 12 : 4} 
        lg={viewMode === 'list' ? 12 : 2.4} 
        key={index}
      >
        {viewMode === 'list' ? (
          <Skeleton 
            variant="rectangular" 
            height={isMobile ? 100 : 120} 
            sx={{ 
              borderRadius: 2,
              transform: 'none',
              animation: 'wave',
            }} 
          />
        ) : (
          <Skeleton 
            variant="rectangular" 
            height={isMobile ? 200 : 300} 
            sx={{ 
              borderRadius: 2,
              transform: 'none',
              animation: 'wave',
            }} 
          />
        )}
      </Grid>
    ))}
  </Grid>
);

const Browse = () => {
  const { t } = useLanguage();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [searchParams, setSearchParams] = useSearchParams();
  const categoryId = searchParams.get('category');
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [videos, setVideos] = useState([]);
  const [categories, setCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [viewMode, setViewMode] = useState('grid');
  const [filters, setFilters] = useState({
    area: searchParams.get('area') || '',
    language: searchParams.get('language') || '',
    year: searchParams.get('year') || '',
    actor: searchParams.get('actor') || '',
    director: searchParams.get('director') || '',
    letter: searchParams.get('letter') || '',
    tag: searchParams.get('tag') || '',
    genre: searchParams.get('genre') || '',
    sort: searchParams.get('sort') || '-vod_level',
    trending: searchParams.get('trending') !== 'false',
    top250: searchParams.get('top250') === 'true',
    highscore: searchParams.get('sort') === '-vod_douban_score'
  });
  const [searchQuery, setSearchQuery] = useState(searchParams.get('q') || '');
  const [selectedMasterCategory, setSelectedMasterCategory] = useState(null);
  const [subcategories, setSubcategories] = useState([]);

  // Add cache for videos
  const [videoCache, setVideoCache] = useState({});
  
  // Create a cache key based on current filters and page
  const cacheKey = useMemo(() => {
    return JSON.stringify({
      categoryId,
      page,
      filters,
    });
  }, [categoryId, page, filters]);

  // Debounced filter change handler
  const debouncedFilterChange = useCallback(
    debounce((key, value) => {
      setPage(1);
      setFilters(prev => ({
        ...prev,
        [key]: value
      }));
    }, 300),
    []
  );

  // Calculate active filters count
  const activeFiltersCount = useMemo(() => 
    Object.entries(filters).filter(([key, value]) => value && value !== 'latest' && key !== 'sort').length,
    [filters]
  );

  // Update URL when filters change
  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        newSearchParams.set(key, value);
      } else {
        newSearchParams.delete(key);
      }
    });
    if (page > 1) {
      newSearchParams.set('page', page.toString());
    } else {
      newSearchParams.delete('page');
    }
    setSearchParams(newSearchParams, { replace: true });
  }, [filters, page]);

  // Add effect to handle tag parameter from URL
  useEffect(() => {
    const tagFromUrl = searchParams.get('tag');
    if (tagFromUrl && tagFromUrl !== filters.tag) {
      setFilters(prev => ({
        ...prev,
        tag: tagFromUrl
      }));
      // Reset page when tag changes
      setPage(1);
    }
  }, [searchParams]);

  const processVideoData = (video) => {
    const cleanList = (value) => {
      if (!value || value === null || value === undefined) return [];
      if (Array.isArray(value)) return value.map(item => String(item || '').trim());
      if (typeof value === 'string') return value.split(',').map(item => item.trim());
      return [String(value || '').trim()];
    };

    return {
      ...video,
      vod_actor: cleanList(video.vod_actor),
      vod_director: cleanList(video.vod_director),
      vod_class: cleanList(video.vod_class)
    };
  };

  // Update fetchVideos to use cache
  useEffect(() => {
    const fetchVideos = async () => {
      // Check cache first
      if (videoCache[cacheKey]) {
        setVideos(videoCache[cacheKey].videos.map(processVideoData));
        setTotalPages(videoCache[cacheKey].totalPages);
        return;
      }

      setLoading(true);
      setError(null);
      try {
        let result;
        const pageSize = 20;
        
        if (filters.top250) {
          result = await getVideosByCategory(
            categoryId || 'all',
            page,
            pageSize,
            'vod_top250_rank',
            { 
              ...filters,
              top250: true,
              page,
              limit: pageSize,
              minTop250Rank: 1,
              maxTop250Rank: 250
            }
          );
        } else if (filters.trending) {
          result = await getVideosByCategory(
            categoryId || 'all',
            page,
            pageSize,
            '-vod_level',
            {
              ...filters,
              minVodLevel: 1,
              page,
              limit: pageSize
            }
          );
        } else if (filters.sort === '-vod_douban_score') {
          result = await getVideosByCategory(
            categoryId || 'all',
            page,
            pageSize,
            '-vod_douban_score',
            {
              ...filters,
              page,
              limit: pageSize,
              minVodDoubanScore: 0
            }
          );
        } else {
          result = await getVideosByCategory(
            categoryId || 'all',
            page,
            pageSize,
            filters.sort,
            {
              ...filters,
              page,
              limit: pageSize
            }
          );
        }

        if (result && result.videos) {
          let processedVideos;
          
          if (filters.top250) {
            processedVideos = result.videos
              .filter(video => video.vod_top250_rank >= 1 && video.vod_top250_rank <= 250)
              .sort((a, b) => a.vod_top250_rank - b.vod_top250_rank)
              .map(processVideoData);
          } else if (filters.sort === '-vod_douban_score') {
            processedVideos = result.videos
              .filter(video => video.vod_douban_score > 0)
              .sort((a, b) => b.vod_douban_score - a.vod_douban_score)
              .map(processVideoData);
          } else {
            processedVideos = result.videos.map(processVideoData);
          }

          // Update cache
          setVideoCache(prev => ({
            ...prev,
            [cacheKey]: {
              videos: processedVideos,
              totalPages: Math.ceil(result.pagination.total / pageSize)
            }
          }));

          setVideos(processedVideos);
          setTotalPages(Math.ceil(result.pagination.total / pageSize));
        } else {
          setVideos([]);
          setTotalPages(0);
        }
      } catch (error) {
        console.error('Error fetching videos:', error);
        setError(translations.errors.failedToLoadVideos);
        setVideos([]);
        setTotalPages(0);
      } finally {
        setLoading(false);
      }
    };
    fetchVideos();
  }, [cacheKey]);

  // Add debounced handlers for advanced filters
  const debouncedActorChange = useCallback(
    debounce((value) => {
      setFilters(prev => ({
        ...prev,
        actor: value
      }));
    }, 500),
    []
  );

  const debouncedDirectorChange = useCallback(
    debounce((value) => {
      setFilters(prev => ({
        ...prev,
        director: value
      }));
    }, 500),
    []
  );

  const debouncedTagChange = useCallback(
    debounce((value) => {
      setFilters(prev => ({
        ...prev,
        tag: value
      }));
    }, 500),
    []
  );

  // Add local state for advanced filter inputs
  const [localFilters, setLocalFilters] = useState({
    actor: '',
    director: '',
    tag: ''
  });

  // Update useEffect for URL params to include local filters
  useEffect(() => {
    setLocalFilters({
      actor: searchParams.get('actor') || '',
      director: searchParams.get('director') || '',
      tag: searchParams.get('tag') || ''
    });
  }, [searchParams]);

  // Update the sort value mapping
  const sortValueMapping = {
    '-vod_level': 'trending',
    '-vod_time': 'latest',
    '-vod_douban_score': 'rating'
  };

  const sortApiMapping = {
    'trending': '-vod_level',
    'latest': '-vod_time',
    'rating': '-vod_douban_score'
  };

  // Update handleFilterChange to use the mappings
  const handleFilterChange = (key, value) => {
    if (key === 'search') {
      setSearchQuery(value);
      if (!value) {
        const newParams = new URLSearchParams(searchParams);
        newParams.delete('q');
        setSearchParams(newParams);
      }
    } else if (key === 'sort') {
      setFilters(prev => ({
        ...prev,
        sort: sortApiMapping[value] || value
      }));
    } else {
      debouncedFilterChange(key, value);
    }
  };

  const handleClearFilters = () => {
    setVideoCache({}); // Clear the cache
    setFilters({
      area: '',
      language: '',
      year: '',
      actor: '',
      director: '',
      letter: '',
      tag: '',
      genre: '',
      sort: '-vod_level',
      trending: true,
      top250: false,
      highscore: false
    });
    setPage(1);
    const newParams = new URLSearchParams(searchParams);
    Object.keys(filters).forEach(key => {
      if (key !== 'trending' && key !== 'sort') {
        newParams.delete(key);
      }
    });
    newParams.set('trending', 'true');
    newParams.set('sort', '-vod_level');
    setSearchParams(newParams);
  };

  const handlePageChange = (_, newPage) => {
    setPage(newPage);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Get translation with fallback
  const getTranslation = (section, key) => {
    try {
      const translated = t(section, key);
      if (translated) return translated;
      
      // Safely check if translations and section exist
      if (translations && translations[section] && translations[section][key]) {
        return translations[section][key];
      }
      return key; // Fallback to the key itself
    } catch {
      return key;
    }
  };

  // Fetch categories
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const mainCategories = [
          { type_id: 1, type_name: '电影' },
          { type_id: 2, type_name: '连续剧' },
          { type_id: 3, type_name: '综艺' },
          { type_id: 4, type_name: '动漫' },
          { type_id: 5, type_name: '短剧' },
          { type_id: 6, type_name: '纪录片' }
        ];
        setCategories(mainCategories);

        const result = await getTypes();
        if (result) {
          setAllCategories(result);
          
          if (categoryId) {
            const mainCategory = mainCategories.find(cat => String(cat.type_id) === categoryId);
            if (mainCategory) {
              setSelectedMasterCategory(mainCategory);
              setCurrentCategory(mainCategory);
              // Get subcategory range based on main category ID
              const ranges = {
                1: { start: 7, end: 13 },
                2: { start: 14, end: 21 },
                3: { start: 22, end: 25 },
                4: { start: 26, end: 30 }
              };
              const range = ranges[mainCategory.type_id];
              
              const subs = result.filter(cat => {
                const id = parseInt(cat.type_id);
                return range && id >= range.start && id <= range.end;
              });
              setSubcategories(subs);
            } else {
              // Handle subcategory selection
              const catId = parseInt(categoryId);
              // Find parent category based on subcategory ID ranges
              let parentId;
              if (catId >= 7 && catId <= 13) parentId = 1;
              else if (catId >= 14 && catId <= 21) parentId = 2;
              else if (catId >= 22 && catId <= 25) parentId = 3;
              else if (catId >= 26 && catId <= 30) parentId = 4;
              
              const mainCategory = mainCategories.find(cat => cat.type_id === parentId);
              if (mainCategory) {
                setSelectedMasterCategory(mainCategory);
                const ranges = {
                  1: { start: 7, end: 13 },
                  2: { start: 14, end: 21 },
                  3: { start: 22, end: 25 },
                  4: { start: 26, end: 30 }
                };
                const range = ranges[mainCategory.type_id];
                
                const subs = result.filter(cat => {
                  const id = parseInt(cat.type_id);
                  return range && id >= range.start && id <= range.end;
                });
                setSubcategories(subs);
                
                // Find and set the current subcategory
                const currentSubCategory = result.find(cat => String(cat.type_id) === categoryId);
                if (currentSubCategory) {
                  setCurrentCategory(currentSubCategory);
                }
              }
            }
          } else {
            setSelectedMasterCategory(null);
            setCurrentCategory(null);
          }
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
        setError(getTranslation('errors', 'failedToLoadCategories'));
      }
    };
    fetchCategories();
  }, [categoryId]);

  // Render breadcrumb navigation
  const renderBreadcrumbs = () => {
    if (!currentCategory) return null;

    // Find parent category if this is a subcategory
    const parentCategory = categories.find(cat => 
      cat.subcategories?.some(sub => sub.type_id === currentCategory.type_id)
    );

    return (
      <Breadcrumbs 
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 2 }}
      >
        <Link
          component={Button}
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"
          href="/"
        >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          {getTranslation('common', 'home')}
        </Link>
        <Link
          component={Button}
          underline="hover"
          color="inherit"
          href="/browse"
        >
          {getTranslation('browse', 'allContent')}
        </Link>
        {parentCategory && (
          <Link
            component={Button}
            underline="hover"
            color="inherit"
            href={`/browse?category=${parentCategory.type_id}`}
          >
            {parentCategory.type_name}
          </Link>
        )}
        <Typography color="text.primary">
          {currentCategory.type_name}
        </Typography>
      </Breadcrumbs>
    );
  };

  const handleCategoryChange = (newCategoryId) => {
    setSearchParams({ category: newCategoryId });
    setPage(1);
    
    // Find and set current category if it's a subcategory
    if (subcategories.length > 0) {
      const subCategory = subcategories.find(sub => String(sub.type_id) === newCategoryId);
      if (subCategory) {
        setCurrentCategory(subCategory);
      } else if (selectedMasterCategory && String(selectedMasterCategory.type_id) === newCategoryId) {
        setCurrentCategory(selectedMasterCategory);
      }
    }
  };

  const toggleFilterDrawer = () => {
    setIsFilterDrawerOpen(!isFilterDrawerOpen);
  };

  // Add effect to fetch genres when type_id changes
  const [dynamicGenres, setDynamicGenres] = useState([]);
  const [isLoadingGenres, setIsLoadingGenres] = useState(false);
  
  // Update effect to fetch genres using parent category when in subcategory
  useEffect(() => {
    const fetchGenres = async () => {
      setIsLoadingGenres(true);
      try {
        // If we're in a subcategory, use the parent category's genres
        let genreCategoryId = categoryId;
        if (selectedMasterCategory && categoryId !== String(selectedMasterCategory.type_id)) {
          // We're in a subcategory, use the master category ID instead
          genreCategoryId = String(selectedMasterCategory.type_id);
        }
        
        const genres = await getGenresByCategory(genreCategoryId || 'all');
        setDynamicGenres(genres);
      } catch (error) {
        console.error('Error fetching genres:', error);
        setDynamicGenres([]);
      } finally {
        setIsLoadingGenres(false);
      }
    };

    fetchGenres();
  }, [categoryId]);

  // Update filterOptions to show loading state in the genre select
  const filterOptions = {
    area: ['中国大陆', '香港', '台湾', '日本', '韩国', '欧美', '其他'],
    language: ['国语', '粤语', '英语', '日语', '韩语', '其他'],
    year: Array.from({ length: 74 }, (_, i) => (new Date().getFullYear() - i).toString()),
    letter: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split(''),
    genre: dynamicGenres
  };

  const [showDiscovery, setShowDiscovery] = useState(false);

  // Enhanced renderFilters function with better organization
  const renderFilters = () => (
    <Stack spacing={2} sx={{ p: 2 }}>
      {/* Enhanced Search field with tooltip */}
      <Tooltip title="输入关键词搜索影视内容" placement="top">
        <TextField
          fullWidth
          label={t('filters', 'search')}
          value={searchQuery}
          onChange={(e) => handleFilterChange('search', e.target.value)}
          onKeyPress={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          helperText="按回车键搜索"
          size="small"
        />
      </Tooltip>

      {/* Quick Filters Section with enhanced visual feedback */}
      <Box>
        <Typography variant="subtitle2" color="text.secondary" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {t('filters', 'quickFilters')}
          <Tooltip title="快速筛选热门和高分内容">
            <IconButton size="small">
              <TuneIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Typography>
        <Stack direction="row" spacing={1} flexWrap="wrap" sx={{ gap: 1 }}>
          <Tooltip title="显示热门内容">
            <Chip
              label={t('filters', 'trending')}
              onClick={() => handleFilterChange('trending', !filters.trending)}
              color={filters.trending ? 'primary' : 'default'}
              variant={filters.trending ? 'filled' : 'outlined'}
              icon={<TrendingUpIcon />}
            />
          </Tooltip>
          <Tooltip title="显示豆瓣Top 250电影">
            <Chip
              label={t('filters', 'top250')}
              onClick={() => handleFilterChange('top250', !filters.top250)}
              color={filters.top250 ? 'primary' : 'default'}
              variant={filters.top250 ? 'filled' : 'outlined'}
              icon={<StarIcon />}
            />
          </Tooltip>
        </Stack>
      </Box>

      <Divider />

      {/* Basic Filters Section with improved organization */}
      <Box>
        <Typography variant="subtitle2" color="text.secondary" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {t('filters', 'basicFilters')}
          <Tooltip title="基本筛选选项">
            <IconButton size="small">
              <FilterListIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Typography>
        <Stack spacing={2}>
          <Tooltip title="选择影视类型">
            <FormControl fullWidth size="small">
              <InputLabel>{t('filters', 'genre')}</InputLabel>
              <Select
                value={filters.genre}
                label={t('filters', 'genre')}
                onChange={(e) => handleFilterChange('genre', e.target.value)}
                disabled={isLoadingGenres}
                startAdornment={
                  isLoadingGenres ? (
                    <InputAdornment position="start">
                      <CircularProgress size={20} />
                    </InputAdornment>
                  ) : null
                }
              >
                <MenuItem value="">
                  <em>{t('browse', 'all')}</em>
                </MenuItem>
                {filterOptions.genre.map(option => (
                  <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Tooltip>

          <Tooltip title="选择地区">
            <FormControl fullWidth size="small">
              <InputLabel>{t('filters', 'area')}</InputLabel>
              <Select
                value={filters.area}
                label={t('filters', 'area')}
                onChange={(e) => handleFilterChange('area', e.target.value)}
              >
                <MenuItem value="">
                  <em>{t('browse', 'all')}</em>
                </MenuItem>
                {filterOptions.area.map(option => (
                  <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Tooltip>

          <Tooltip title="选择语言">
            <FormControl fullWidth size="small">
              <InputLabel>{t('filters', 'language')}</InputLabel>
              <Select
                value={filters.language}
                label={t('filters', 'language')}
                onChange={(e) => handleFilterChange('language', e.target.value)}
              >
                <MenuItem value="">
                  <em>{t('browse', 'all')}</em>
                </MenuItem>
                {filterOptions.language.map(option => (
                  <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Tooltip>

          <Tooltip title="选择年份">
            <FormControl fullWidth size="small">
              <InputLabel>{t('filters', 'year')}</InputLabel>
              <Select
                value={filters.year}
                label={t('filters', 'year')}
                onChange={(e) => handleFilterChange('year', e.target.value)}
              >
                <MenuItem value="">
                  <em>{t('browse', 'all')}</em>
                </MenuItem>
                {filterOptions.year.map(option => (
                  <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Tooltip>
        </Stack>
      </Box>

      <Divider />

      {/* Advanced Filters Section with improved feedback */}
      <Box>
        <Typography variant="subtitle2" color="text.secondary" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {t('filters', 'advancedFilters')}
          <Tooltip title="高级筛选选项">
            <IconButton size="small">
              <TuneIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Typography>
        <Stack spacing={2}>
          <Tooltip title="按演员筛选">
            <TextField
              fullWidth
              size="small"
              label={t('filters', 'actor')}
              value={localFilters.actor}
              onChange={(e) => {
                const value = e.target.value;
                setLocalFilters(prev => ({ ...prev, actor: value }));
                debouncedActorChange(value);
              }}
              helperText="输入演员名称后等待自动搜索"
            />
          </Tooltip>
          <Tooltip title="按导演筛选">
            <TextField
              fullWidth
              size="small"
              label={t('filters', 'director')}
              value={localFilters.director}
              onChange={(e) => {
                const value = e.target.value;
                setLocalFilters(prev => ({ ...prev, director: value }));
                debouncedDirectorChange(value);
              }}
              helperText="输入导演名称后等待自动搜索"
            />
          </Tooltip>
          <Tooltip title="按标签筛选">
            <TextField
              fullWidth
              size="small"
              label={t('filters', 'tag')}
              value={localFilters.tag}
              onChange={(e) => {
                const value = e.target.value;
                setLocalFilters(prev => ({ ...prev, tag: value }));
                debouncedTagChange(value);
              }}
              helperText="输入标签关键词后等待自动搜索"
            />
          </Tooltip>
        </Stack>
      </Box>

      <Divider />
      {/* Enhanced Clear Filters Button */}
      {activeFiltersCount > 0 && (
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          onClick={handleClearFilters}
          startIcon={<ClearAllIcon />}
          sx={{
            mt: 2,
            borderStyle: 'dashed',
            '&:hover': {
              borderStyle: 'solid'
            }
          }}
        >
          {t('filters', 'clearAllFilters')} ({activeFiltersCount})
        </Button>
      )}
    </Stack>
  );

  // Render active filters
  const renderActiveFilters = () => {
    const activeFilters = Object.entries(filters).filter(([key, value]) => {
      if (key === 'top250') return value === true;
      if (key === 'trending') return value === true;
      return value && value !== 'latest' && key !== 'sort';
    });

    if (activeFilters.length === 0) return null;

    return (
      <Box sx={{ mb: 3, mt: 1 }}>
        <Stack direction="row" spacing={1} flexWrap="wrap" sx={{ gap: 1 }}>
          {activeFilters.map(([key, value]) => {
            let displayValue = value;
            if (key === 'trending' && value === true) {
              displayValue = translations.filters.trending;
            } else if (key === 'top250' && value === true) {
              displayValue = translations.filters.top250;
            }
            
            return (
              <Chip
                key={key}
                label={key === 'trending' || key === 'top250' ? displayValue : `${translations.filters[key]}: ${displayValue}`}
                onDelete={() => handleFilterChange(key, key === 'top250' || key === 'trending' ? false : '')}
                color="primary"
                variant="outlined"
                size="small"
                sx={{
                  borderRadius: 1,
                  '&:hover': {
                    bgcolor: alpha(theme.palette.primary.main, 0.1),
                  },
                }}
              />
            );
          })}
          {activeFilters.length > 0 && (
            <Chip
              icon={<ClearAllIcon />}
              label={translations.filters.clearAll}
              onClick={handleClearFilters}
              color="primary"
              variant="outlined"
              size="small"
              sx={{
                borderRadius: 1,
                '&:hover': {
                  bgcolor: alpha(theme.palette.primary.main, 0.1),
                },
              }}
            />
          )}
        </Stack>
      </Box>
    );
  };

  const handleSearch = (e) => {
    if (e.key === 'Enter' && searchQuery.trim()) {
      navigate(`/search?q=${encodeURIComponent(searchQuery.trim())}`);
    }
  };

  const handleMasterCategoryChange = (masterId) => {
    if (masterId === 'all') {
      setSelectedMasterCategory(null);
      setSubcategories([]);
      setCurrentCategory(null);
      handleCategoryChange('all');
      return;
    }

    const masterCategory = categories.find(cat => String(cat.type_id) === masterId);
    if (masterCategory) {
      setSelectedMasterCategory(masterCategory);
      setCurrentCategory(masterCategory);
      const mainId = parseInt(masterId);
      const ranges = {
        1: { start: 7, end: 13 },
        2: { start: 14, end: 21 },
        3: { start: 22, end: 25 },
        4: { start: 26, end: 30 }
      };
      const range = ranges[mainId];
      
      const subs = allCategories.filter(cat => {
        const id = parseInt(cat.type_id);
        return range && id >= range.start && id <= range.end;
      });
      setSubcategories(subs);
      handleCategoryChange(masterId);
    }
  };

  // Update the getCategoryKey function to include animation subcategories
  const getCategoryKey = (chineseName) => {
    const categoryMap = {
      // Main categories
      '电影': 'movies',
      '连续剧': 'tvSeries',
      '综艺': 'variety',
      '动漫': 'animation',
      '短剧': 'shortSeries',
      '纪录片': 'documentary',
      // TV Series subcategories
      '国产剧': 'mainlandSeries',
      '香港剧': 'hongKongSeries',
      '韩国剧': 'koreanSeries',
      '欧美剧': 'westernSeries',
      '台湾剧': 'taiwanSeries',
      '日本剧': 'japaneseSeries',
      '海外剧': 'overseasSeries',
      '泰国剧': 'thaiSeries',
      // Animation subcategories
      '国产动漫': 'chineseAnimation',
      '日韩动漫': 'japaneseKoreanAnimation',
      '欧美动漫': 'westernAnimation',
      '港台动漫': 'hkTaiwanAnimation',
      '海外动漫': 'internationalAnimation'
    };
    return categoryMap[chineseName] || chineseName;
  };

  return (
    <>
      <Helmet>
        <title>
          {selectedMasterCategory
            ? `${selectedMasterCategory.type_name}${currentCategory && currentCategory.type_id !== selectedMasterCategory.type_id ? ` - ${currentCategory.type_name}` : ''} - 247看`
            : translations.browse.metaTitle}
        </title>
        <meta name="description" content={
          selectedMasterCategory
            ? `247看${selectedMasterCategory.type_name}频道，${(() => {
                switch(selectedMasterCategory.type_id) {
                  case 1: // 电影
                    return `提供最新电影、热映电影在线观看，1080P蓝光高清电影，支持手机观看、极速播放，无广告，免费在线播放，同步更新热门影片，支持多线路切换。`;
                  case 2: // 连续剧
                    return `提供${currentCategory ? currentCategory.type_name + '、' : ''}国产剧、韩剧、美剧、日剧、港剧、台剧、泰剧在线观看，支持追剧、极速播放，1080P蓝光画质，无广告，免费在线更新，多线路播放。`;
                  case 3: // 综艺
                    return `提供最新综艺节目、热门综艺在线观看，支持手机观看、极速播放，1080P蓝光画质，无广告，免费在线播放，同步更新，支持多线路切换。`;
                  case 4: // 动漫
                    return `提供${currentCategory ? currentCategory.type_name + '、' : ''}新番动漫、热门动漫在线观看，支持追番、极速播放，1080P蓝光画质，无广告，免费在线播放，同步更新，多线路播放。`;
                  case 5: // 短剧
                    return `提供最新短剧、热门短剧在线观看，支持手机观看、极速播放，1080P蓝光画质，无广告，免费在线播放，同步更新，支持多线路切换。`;
                  case 6: // 纪录片
                    return `提供高清纪录片在线观看，支持手机观看、极速播放，1080P蓝光画质，无广告，免费在线播放，同步更新，支持多线路切换。`;
                  default:
                    return `提供在线观看，1080P蓝光高清，支持手机观看、极速播放，无广告，免费在线播放，同步更新，支持多线路切换。`;
                }
              })()}`
            : translations.browse.metaDescription
        } />
        <meta name="keywords" content={
          selectedMasterCategory
            ? `${(() => {
                const commonKeywords = '1080P高清,蓝光画质,无广告播放,免费观看,在线播放,直接观看,无需下载,手机观看,免费高清,同步更新,实时更新,在线串流,极速播放,多线路';
                switch(selectedMasterCategory.type_id) {
                  case 1: // 电影
                    return `在线电影,高清电影,${selectedMasterCategory.type_name},${currentCategory ? currentCategory.type_name + ',' : ''}最新电影,热映电影,在线影视,电影在线观看,${commonKeywords}`;
                  case 2: // 连续剧
                    return `连续剧在线,剧集,${selectedMasterCategory.type_name},${currentCategory ? currentCategory.type_name + ',' : ''}追剧,国产剧,韩剧在线,美剧,日剧,港剧,台剧,泰剧,海外剧,热播剧集,${commonKeywords}`;
                  case 3: // 综艺
                    return `综艺节目,${selectedMasterCategory.type_name},${currentCategory ? currentCategory.type_name + ',' : ''}在线综艺,最新综艺,热门综艺,综艺在线观看,${commonKeywords}`;
                  case 4: // 动漫
                    return `动漫视频,${selectedMasterCategory.type_name},${currentCategory ? currentCategory.type_name + ',' : ''}追番,新番动漫,热门动漫,动漫在线观看,${commonKeywords}`;
                  case 5: // 短剧
                    return `短剧,${selectedMasterCategory.type_name},${currentCategory ? currentCategory.type_name + ',' : ''}最新短剧,热门短剧,短剧在线观看,${commonKeywords}`;
                  case 6: // 纪录片
                    return `纪录片,${selectedMasterCategory.type_name},${currentCategory ? currentCategory.type_name + ',' : ''}高清纪录片,纪录片在线观看,${commonKeywords}`;
                  default:
                    return `在线影视,${selectedMasterCategory.type_name},${currentCategory ? currentCategory.type_name + ',' : ''}${commonKeywords}`;
                }
              })()}`
            : translations.browse.metaKeywords
        } />
        <meta property="og:title" content={
          selectedMasterCategory
            ? `${selectedMasterCategory.type_name}${currentCategory && currentCategory.type_id !== selectedMasterCategory.type_id ? ` - ${currentCategory.type_name}` : ''} - 247看`
            : translations.browse.metaTitle
        } />
        <meta property="og:description" content={
          selectedMasterCategory
            ? `247看${selectedMasterCategory.type_name}频道，${(() => {
                switch(selectedMasterCategory.type_id) {
                  case 1: // 电影
                    return `提供最新电影、热映电影在线观看，1080P蓝光高清电影，支持手机观看、极速播放，无广告，免费在线播放，同步更新热门影片，支持多线路切换。`;
                  case 2: // 连续剧
                    return `提供${currentCategory ? currentCategory.type_name + '、' : ''}国产剧、韩剧、美剧、日剧、港剧、台剧、泰剧在线观看，支持追剧、极速播放，1080P蓝光画质，无广告，免费在线更新，多线路播放。`;
                  case 3: // 综艺
                    return `提供最新综艺节目、热门综艺在线观看，支持手机观看、极速播放，1080P蓝光画质，无广告，免费在线播放，同步更新，支持多线路切换。`;
                  case 4: // 动漫
                    return `提供${currentCategory ? currentCategory.type_name + '、' : ''}新番动漫、热门动漫在线观看，支持追番、极速播放，1080P蓝光画质，无广告，免费在线播放，同步更新，多线路播放。`;
                  case 5: // 短剧
                    return `提供最新短剧、热门短剧在线观看，支持手机观看、极速播放，1080P蓝光画质，无广告，免费在线播放，同步更新，支持多线路切换。`;
                  case 6: // 纪录片
                    return `提供高清纪录片在线观看，支持手机观看、极速播放，1080P蓝光画质，无广告，免费在线播放，同步更新，支持多线路切换。`;
                  default:
                    return `提供在线观看，1080P蓝光高清，支持手机观看、极速播放，无广告，免费在线播放，同步更新，支持多线路切换。`;
                }
              })()}`
            : translations.browse.metaDescription
        } />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Layout>
        <Container maxWidth="xl" sx={{ 
          py: { xs: 2, md: 4 },
          px: { xs: 0, md: 3 }
        }}>
          <Box sx={{ 
            position: 'fixed', 
            top: { xs: 'auto', md: 80 }, 
            bottom: { xs: 80, md: 'auto' },
            right: { xs: 16, md: 24 }, 
            zIndex: 1200,
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}>
            <Fade in={!showDiscovery}>
              <Box sx={{
                bgcolor: 'background.paper',
                borderRadius: 2,
                p: 1,
                display: { xs: 'none', md: 'flex' },
                alignItems: 'center',
                boxShadow: 3,
                animation: 'float 3s ease-in-out infinite',
                '@keyframes float': {
                  '0%': {
                    transform: 'translateY(0px)',
                  },
                  '50%': {
                    transform: 'translateY(-10px)',
                  },
                  '100%': {
                    transform: 'translateY(0px)',
                  },
                }
              }}>
                <Typography 
                  variant="body2" 
                  color="primary" 
                  sx={{ 
                    mr: 1,
                    fontWeight: 500
                  }}
                >
                  试试发现新内容 ✨
                </Typography>
                <KeyboardArrowRightIcon fontSize="small" color="primary" />
              </Box>
            </Fade>
            <Tooltip title={showDiscovery ? "返回浏览" : "发现新内容"} placement="left">
              <Button
                variant={showDiscovery ? "contained" : "outlined"}
                color="primary"
                onClick={() => setShowDiscovery(!showDiscovery)}
                startIcon={!isMobile && <ExploreIcon />}
                sx={{
                  borderRadius: 8,
                  px: { xs: 2, md: 2.5 },
                  py: { xs: 1.5, md: 1.5 },
                  boxShadow: showDiscovery ? 8 : 4,
                  bgcolor: showDiscovery ? 'primary.main' : 'background.paper',
                  border: !showDiscovery ? '2px solid' : 'none',
                  borderColor: 'primary.main',
                  animation: !showDiscovery ? 'pulse 2s infinite, bounce 1s infinite' : 'none',
                  '&:hover': {
                    boxShadow: 6,
                    transform: 'translateY(-2px)',
                    bgcolor: showDiscovery ? 'primary.dark' : 'background.paper'
                  },
                  transition: 'all 0.2s ease-in-out',
                  '@keyframes pulse': {
                    '0%': {
                      boxShadow: '0 0 0 0 rgba(25, 118, 210, 0.4)'
                    },
                    '70%': {
                      boxShadow: '0 0 0 10px rgba(25, 118, 210, 0)'
                    },
                    '100%': {
                      boxShadow: '0 0 0 0 rgba(25, 118, 210, 0)'
                    }
                  },
                  '@keyframes bounce': {
                    '0%, 100%': {
                      transform: 'translateY(0)'
                    },
                    '50%': {
                      transform: 'translateY(-5px)'
                    }
                  }
                }}
              >
                {!isMobile && (showDiscovery ? "返回浏览" : "发现新内容 ✨")}
                {isMobile && (
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    gap: 0.5 
                  }}>
                    <ExploreIcon />
                    {!showDiscovery && "✨"}
                  </Box>
                )}
              </Button>
            </Tooltip>
          </Box>

          {renderBreadcrumbs()}

          {/* Master Categories Sidebar */}
          <Grid container spacing={isMobile ? 1 : 3}>
            {!showDiscovery && (
              <>
                {/* Mobile Category Navigation */}
                {isMobile && !showDiscovery && (
                  <>
                    {/* Main Categories - Mobile */}
                    <Box 
                      sx={{ 
                        width: '100%', 
                        bgcolor: 'background.paper',
                        position: 'sticky',
                        top: 56,
                        zIndex: 1100,
                        borderBottom: 1,
                        borderColor: 'divider',
                        px: 0
                      }}
                    >
                      <Tabs
                        value={selectedMasterCategory ? String(selectedMasterCategory.type_id) : 'all'}
                        onChange={(_, newValue) => handleMasterCategoryChange(newValue)}
                        variant="scrollable"
                        scrollButtons={false}
                        TabIndicatorProps={{
                          sx: {
                            height: 3,
                            borderRadius: '3px 3px 0 0'
                          }
                        }}
                        sx={{
                          minHeight: 44,
                          '& .MuiTab-root': {
                            minHeight: 44,
                            py: 1,
                            px: 2,
                            minWidth: 'auto',
                            fontSize: '0.9rem',
                            fontWeight: selectedMasterCategory ? 400 : 500
                          }
                        }}
                      >
                        <Tab 
                          label="全部" 
                          value="all"
                        />
                        {categories.map((category) => (
                          <Tab
                            key={category.type_id}
                            label={category.type_name}
                            value={String(category.type_id)}
                          />
                        ))}
                      </Tabs>
                    </Box>

                    {/* Subcategories - Mobile */}
                    {selectedMasterCategory && subcategories.length > 0 && (
                      <Box 
                        sx={{ 
                          width: '100%',
                          bgcolor: 'background.default',
                          position: 'sticky',
                          top: 100,
                          zIndex: 1090,
                          mb: 0,
                          px: 0,
                          borderBottom: 1,
                          borderColor: 'divider',
                          bgcolor: 'background.paper'
                        }}
                      >
                        <Stack
                          direction="row"
                          spacing={1}
                          sx={{
                            py: 1,
                            px: 1,
                            overflowX: 'auto',
                            flexWrap: 'nowrap',
                            '&::-webkit-scrollbar': { display: 'none' },
                            scrollbarWidth: 'none'
                          }}
                        >
                          <Chip
                            label="全部"
                            onClick={() => handleCategoryChange(String(selectedMasterCategory.type_id))}
                            color={categoryId === String(selectedMasterCategory.type_id) ? "primary" : "default"}
                            variant={categoryId === String(selectedMasterCategory.type_id) ? "filled" : "outlined"}
                            size="small"
                          />
                          {subcategories.map((subCat) => (
                            <Chip
                              key={subCat.type_id}
                              label={subCat.type_name}
                              onClick={() => handleCategoryChange(String(subCat.type_id))}
                              color={categoryId === String(subCat.type_id) ? "primary" : "default"}
                              variant={categoryId === String(subCat.type_id) ? "filled" : "outlined"}
                              size="small"
                            />
                          ))}
                        </Stack>
                      </Box>
                    )}
                  </>
                )}

                {/* Desktop Category Navigation */}
                {!isMobile && !showDiscovery && (
                  <Grid item xs={12} md={2}>
                    <Paper 
                      elevation={0} 
                      sx={{ 
                        bgcolor: 'background.paper', 
                        position: 'sticky',
                        top: 80,
                        borderRadius: 2,
                        overflow: 'hidden'
                      }}
                    >
                      {/* Main Categories - Desktop */}
                      <List sx={{ p: 0 }}>
                        <ListItemButton
                          selected={!selectedMasterCategory}
                          onClick={() => handleMasterCategoryChange('all')}
                          sx={{ 
                            py: 1.5,
                            borderLeft: 3,
                            borderColor: !selectedMasterCategory ? 'primary.main' : 'transparent'
                          }}
                        >
                          <ListItemText 
                            primary="全部" 
                            primaryTypographyProps={{
                              fontSize: '0.95rem',
                              fontWeight: !selectedMasterCategory ? 600 : 400
                            }}
                          />
                        </ListItemButton>
                        
                        {categories.map((category) => (
                          <React.Fragment key={category.type_id}>
                            <ListItemButton
                              selected={selectedMasterCategory?.type_id === category.type_id}
                              onClick={() => handleMasterCategoryChange(String(category.type_id))}
                              sx={{ 
                                py: 1.5,
                                borderLeft: 3,
                                borderColor: selectedMasterCategory?.type_id === category.type_id ? 'primary.main' : 'transparent'
                              }}
                            >
                              <ListItemText 
                                primary={category.type_name}
                                primaryTypographyProps={{
                                  fontSize: '0.95rem',
                                  fontWeight: selectedMasterCategory?.type_id === category.type_id ? 600 : 400
                                }}
                              />
                              {subcategories.length > 0 && selectedMasterCategory?.type_id === category.type_id && (
                                <KeyboardArrowRightIcon 
                                  sx={{ 
                                    transform: 'rotate(-90deg)', 
                                    color: 'primary.main',
                                    transition: 'transform 0.2s'
                                  }} 
                                />
                              )}
                            </ListItemButton>

                            {/* Subcategories Collapse */}
                            {selectedMasterCategory?.type_id === category.type_id && subcategories.length > 0 && (
                              <Box sx={{ bgcolor: 'action.hover', py: 1 }}>
                                <List sx={{ p: 0 }}>
                                  <ListItemButton
                                    selected={categoryId === String(selectedMasterCategory.type_id)}
                                    onClick={() => handleCategoryChange(String(selectedMasterCategory.type_id))}
                                    sx={{ 
                                      py: 1,
                                      pl: 4,
                                      minHeight: 40
                                    }}
                                  >
                                    <ListItemText 
                                      primary="全部"
                                      primaryTypographyProps={{
                                        fontSize: '0.9rem',
                                        color: 'text.secondary'
                                      }}
                                    />
                                  </ListItemButton>
                                  {subcategories.map((subCat) => (
                                    <ListItemButton
                                      key={subCat.type_id}
                                      selected={categoryId === String(subCat.type_id)}
                                      onClick={() => handleCategoryChange(String(subCat.type_id))}
                                      sx={{ 
                                        py: 1,
                                        pl: 4,
                                        minHeight: 40
                                      }}
                                    >
                                      <ListItemText 
                                        primary={subCat.type_name}
                                        primaryTypographyProps={{
                                          fontSize: '0.9rem',
                                          color: 'text.secondary'
                                        }}
                                      />
                                    </ListItemButton>
                                  ))}
                                </List>
                              </Box>
                            )}
                          </React.Fragment>
                        ))}
                      </List>
                    </Paper>
                  </Grid>
                )}
              </>
            )}

            {/* Main Content Area */}
            <Grid item xs={12} md={showDiscovery ? 12 : 10}>
              <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                minHeight: '100vh',
                position: 'relative',
                pt: { xs: 1, md: 3 }
              }}>
                {/* Quick Filter Tabs - Only show when not in discovery mode */}
                {!showDiscovery && (
                  <Paper 
                    elevation={0} 
                    sx={{ 
                      mb: { xs: 2, md: 3 },
                      mt: { 
                        xs: selectedMasterCategory ? 
                            (subcategories.length > 0 ? -1 : 0) : 5, 
                        md: selectedMasterCategory ? 
                            (subcategories.length > 0 ? 0 : 0) : 3 
                      },
                      bgcolor: 'background.paper',
                      borderRadius: 2,
                      overflow: 'hidden',
                    }}
                  >
                    <Tabs 
                      value={filters.top250 ? 'top250' : (filters.trending ? 'trending' : (filters.sort === '-vod_douban_score' ? 'highscore' : (filters.sort === '-vod_time' ? 'latest' : 'trending')))}
                      onChange={(_, newValue) => {
                        if (newValue === 'top250') {
                          setFilters(prev => ({
                            ...prev,
                            top250: true,
                            trending: false,
                            sort: 'vod_top250_rank'
                          }));
                        } else if (newValue === 'trending') {
                          setFilters(prev => ({
                            ...prev,
                            top250: false,
                            trending: true,
                            sort: '-vod_level'
                          }));
                        } else if (newValue === 'latest') {
                          setFilters(prev => ({
                            ...prev,
                            top250: false,
                            trending: false,
                            sort: '-vod_time'
                          }));
                        } else if (newValue === 'highscore') {
                          setFilters(prev => ({
                            ...prev,
                            top250: false,
                            trending: false,
                            sort: '-vod_douban_score'
                          }));
                        }
                        setPage(1);
                        setVideoCache({});
                      }}
                      variant="scrollable"
                      scrollButtons={isMobile ? "auto" : false}
                      sx={{ 
                        px: { xs: 1, md: 2 },
                        '& .MuiTab-root': {
                          minHeight: { xs: 48, md: 56 },
                          fontSize: { xs: '0.875rem', md: '1rem' },
                          px: { xs: 1, md: 2 }
                        }
                      }}
                    >
                      <Tab 
                        label={t('filters', 'trending')} 
                        value="trending"
                        icon={<TrendingUpIcon />}
                        iconPosition="start"
                      />
                      <Tab 
                        label={t('browse', 'latest')} 
                        value="latest"
                        icon={<UpdateIcon />}
                        iconPosition="start"
                      />
                      <Tab 
                        label="高分" 
                        value="highscore"
                        icon={<StarIcon />}
                        iconPosition="start"
                      />
                      {/* Only show Top 250 tab for movies (type_id: 1) */}
                      {(!selectedMasterCategory || selectedMasterCategory?.type_id === 1) && (
                        <Tab 
                          label={t('filters', 'top250')} 
                          value="top250"
                          icon={<StarIcon />}
                          iconPosition="start"
                        />
                      )}
                    </Tabs>
                  </Paper>
                )}

                {/* Content Discovery Section */}
                {showDiscovery ? (
                  <Box sx={{ mb: 4 }}>
                    <Paper elevation={0} sx={{ p: 3, bgcolor: 'background.paper', mt: { xs: 2, md: 3 } }}>
                      <Typography variant="h5" gutterBottom sx={{ mb: 4, mt: 2 }}>
                        {t('discovery', 'title') || '发现新内容'}
                      </Typography>
                      <ContentDiscovery 
                        onMovieSelect={(movie) => {
                          setSearchQuery(movie.title);
                          navigate(`/search?q=${encodeURIComponent(movie.title)}`);
                          setShowDiscovery(false);
                        }}
                      />
                    </Paper>
                  </Box>
                ) : (
                  <>
                    {/* Top bar with filters */}
                    <Paper elevation={0} sx={{ p: 2, mb: 3, bgcolor: 'background.paper', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', gap: 2 }}>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Button
                          startIcon={<TuneIcon />}
                          onClick={toggleFilterDrawer}
                          variant={isFilterDrawerOpen ? "contained" : "outlined"}
                          color="primary"
                        >
                          {t('filters', 'title')}
                          {activeFiltersCount > 0 && (
                            <Chip 
                              label={activeFiltersCount} 
                              size="small" 
                              color="primary"
                              sx={{ height: 20, fontSize: '0.75rem' }}
                            />
                          )}
                        </Button>
                        <ToggleButtonGroup
                          value={viewMode}
                          exclusive
                          onChange={(_, newMode) => newMode && setViewMode(newMode)}
                          size="small"
                        >
                          <ToggleButton value="grid" aria-label={t('filters', 'gridView')}>
                            <ViewModuleIcon />
                          </ToggleButton>
                          <ToggleButton value="list" aria-label={t('filters', 'listView')}>
                            <ViewListIcon />
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Stack>
                      
                      <Typography color="text.secondary" variant="body2">
                        {videos.length > 0 
                          ? t('filters', 'resultsCount').replace('${count}', videos.length)
                          : t('browse', 'noContent')}
                      </Typography>
                    </Paper>

                    {/* Active filters */}
                    {renderActiveFilters()}

                    {/* Content */}
                    {loading ? (
                      <LoadingSkeleton viewMode={viewMode} isMobile={isMobile} />
                    ) : error ? (
                      <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                      </Alert>
                    ) : videos.length === 0 ? (
                      <Box sx={{ py: 8, textAlign: 'center', color: 'text.secondary' }}>
                        <Typography variant="h6" gutterBottom>
                          {t('browse', 'noVideosFound')}
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 3 }}>
                          {t('browse', 'tryDifferentFilters')}
                        </Typography>
                        {(activeFiltersCount > 0 || filters.top250 || filters.trending) && (
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleClearFilters}
                            startIcon={<ClearAllIcon />}
                          >
                            {t('filters', 'clearAllFilters')}
                          </Button>
                        )}
                      </Box>
                    ) : (
                      <>
                        <Grid container spacing={isMobile ? 1 : 2}>
                          {videos.map((video) => (
                            <Grid 
                              item 
                              xs={viewMode === 'list' ? 12 : 6} 
                              sm={viewMode === 'list' ? 12 : 6} 
                              md={viewMode === 'list' ? 12 : 4} 
                              lg={viewMode === 'list' ? 12 : 2.4} 
                              key={video.vod_id}
                            >
                              {viewMode === 'list' ? (
                                <Paper 
                                  elevation={1} 
                                  sx={{ 
                                    p: { xs: 1.5, md: 2 },
                                    '&:hover': {
                                      boxShadow: (theme) => theme.shadows[4],
                                      transform: 'translateY(-2px)',
                                    },
                                    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                                  }}
                                >
                                  <MovieDetail 
                                    movie={video} 
                                    isListView={true} 
                                    onPlay={() => navigate(`/watch/${video.vod_id}`)}
                                    isMobile={isMobile}
                                  />
                                </Paper>
                              ) : (
                                <Box
                                  sx={{
                                    height: '100%',
                                    '&:hover': {
                                      transform: isMobile ? 'none' : 'translateY(-4px)',
                                      '& .MuiPaper-root': {
                                        boxShadow: (theme) => theme.shadows[isMobile ? 2 : 8],
                                      }
                                    },
                                    transition: 'transform 0.2s ease-in-out',
                                  }}
                                >
                                  <ContentCard 
                                    content={video} 
                                    isMobile={isMobile}
                                  />
                                </Box>
                              )}
                            </Grid>
                          ))}
                        </Grid>
                        
                        {/* Pagination */}
                        {totalPages > 1 && (
                          <Box 
                            sx={{ 
                              display: 'flex', 
                              justifyContent: 'center', 
                              mt: { xs: 3, md: 4 },
                              mb: { xs: 4, md: 2 },
                              '& .MuiPagination-ul': {
                                flexWrap: 'nowrap',
                              }
                            }}
                          >
                            <Pagination
                              count={totalPages}
                              page={page}
                              onChange={handlePageChange}
                              color="primary"
                              size={isMobile ? "small" : "medium"}
                              showFirstButton
                              showLastButton
                              siblingCount={isMobile ? 0 : 1}
                              boundaryCount={isMobile ? 1 : 2}
                            />
                          </Box>
                        )}
                      </>
                    )}
                  </>
                )}
              </Box>
            </Grid>
          </Grid>

          {/* Filter drawer */}
          <Drawer
            anchor={isMobile ? 'bottom' : 'right'}
            open={isFilterDrawerOpen}
            onClose={toggleFilterDrawer}
            PaperProps={{
              sx: {
                width: isMobile ? '100%' : 350,
                height: isMobile ? '90vh' : '100%',
                p: { xs: 2, md: 2 },
                borderTopLeftRadius: isMobile ? 16 : 0,
                borderTopRightRadius: isMobile ? 16 : 0,
                overflowY: 'auto',
                bgcolor: 'background.paper',
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  background: 'transparent',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: (theme) => theme.palette.divider,
                  borderRadius: '4px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: (theme) => theme.palette.action.hover,
                },
              }
            }}
          >
            <Box sx={{ 
              position: 'sticky', 
              top: 0, 
              zIndex: 1,
              pb: 2 
            }}>
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center', 
                mb: 2 
              }}>
                <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <FilterListIcon />
                  {t('filters', 'title')}
                  {activeFiltersCount > 0 && (
                    <Chip 
                      label={activeFiltersCount} 
                      size="small" 
                      color="primary"
                      sx={{ height: 20, fontSize: '0.75rem' }}
                    />
                  )}
                </Typography>
                <IconButton onClick={toggleFilterDrawer} size="small">
                  <CloseIcon />
                </IconButton>
              </Box>
              <Divider />
            </Box>
            {renderFilters()}
          </Drawer>
        </Container>
      </Layout>
    </>
  );
};

export default Browse; 