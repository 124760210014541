import React from 'react';
import { Box, Typography, Stack, Button, useTheme, alpha } from '@mui/material';
import { useLanguage } from '../../contexts/LanguageContext';

const RouteSelector = ({ 
  routes, 
  selectedRoute, 
  onRouteSelect,
  routeNames 
}) => {
  const theme = useTheme();
  const { t } = useLanguage();
  
  if (!routes?.length) return null;

  // Define route priority (蓝光 routes first, then 备用 routes)
  const routePriority = {
    'heimuer': 1,  // 蓝光H
    'ffm3u8': 2,   // 蓝光F
    'ikm3u8': 3,   // 蓝光I
    '1080zyk': 4,  // 蓝光Y
    'bfzym3u8': 5, // 备用B
    'okm3u8': 6,   // 备用O
  };

  // Sort routes and keep track of original indices
  const sortedRoutesWithIndices = routes.map((route, index) => ({
    route,
    originalIndex: index,
    priority: routePriority[route] || 999
  })).sort((a, b) => a.priority - b.priority);
  
  return (
    <Box sx={{ mb: 3 }}>
      <Typography 
        variant="subtitle1" 
        gutterBottom 
        sx={{ 
          color: 'text.primary',
          fontWeight: 600,
          mb: 2
        }}
      >
        {t('videoPlayer', 'routeSelection')}
      </Typography>
      <Stack direction="row" spacing={1} flexWrap="wrap" sx={{ gap: 1 }}>
        {sortedRoutesWithIndices.map(({ route, originalIndex }) => (
          <Button
            key={route}
            variant={selectedRoute === originalIndex ? "contained" : "outlined"}
            size="medium"
            onClick={() => onRouteSelect(originalIndex)}
            sx={{
              color: selectedRoute === originalIndex ? 'white' : 'text.primary',
              borderColor: 'grey.300',
              '&:hover': {
                bgcolor: selectedRoute === originalIndex 
                  ? 'primary.dark'
                  : alpha(theme.palette.common.black, 0.1),
              },
            }}
          >
            {t('videoPlayer', route)}
          </Button>
        ))}
      </Stack>
    </Box>
  );
};

export default RouteSelector; 