import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Box,
  Stack,
  Typography,
  Card,
  IconButton,
  TextField,
  Button,
  CircularProgress
} from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { getComments, addComment, updateCommentVote } from '../../services/api';
import { useLanguage } from '../../contexts/LanguageContext';
import { generateAnonymousName } from '../../utils/nameGenerator';
import Cookies from 'js-cookie';
import { useAuth } from '../../contexts/AuthContext';

// Maximum nesting level for comments
const MAX_NESTING_LEVEL = 3;

// Helper function to manage vote cookies
const manageVoteCookie = (commentId, voteType) => {
  const cookieName = `comment_vote_${commentId}`;
  const existingVote = Cookies.get(cookieName);
  
  if (existingVote) {
    return true; // Already voted on this comment
  }
  
  // Set the cookie with 30 days expiry
  Cookies.set(cookieName, voteType, { 
    expires: 30,
    path: '/',
    sameSite: 'Lax'
  });
  return false;
};

const Comment = ({ comment, level = 0, onReply, anonymousName, isLocalhost, loading, videoId, onCommentUpdate }) => {
  const { t } = useLanguage();
  const { user } = useAuth();
  const [replyContent, setReplyContent] = useState('');
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [codeId, setCodeId] = useState(null);
  const [captchaSvg, setCaptchaSvg] = useState('');
  const [verificationError, setVerificationError] = useState(null);

  // Reset verification state when showing/hiding form
  useEffect(() => {
    if (!showReplyForm) {
      setVerificationCode('');
      setVerificationError(null);
    } else {
      fetchVerificationCode();
    }
  }, [showReplyForm]);

  const fetchVerificationCode = async () => {
    try {
      const response = await fetch('/api/verification-code');
      const data = await response.json();
      if (data.status === 'success') {
        setCodeId(data.data.codeId);
        setCaptchaSvg(data.data.svgData);
      }
    } catch (error) {
      console.error('Failed to fetch verification code:', error);
      setVerificationError(t('common', 'verificationCodeError'));
    }
  };

  const handleReplySubmit = async (e) => {
    e.preventDefault();
    if (!replyContent.trim()) return;

    try {
      if (!verificationCode) {
        setVerificationError(t('common', 'invalidVerificationCode'));
        return;
      }

      await onReply(comment.comment_id, replyContent, codeId, verificationCode);
      setReplyContent('');
      setShowReplyForm(false);
      setVerificationCode('');
      fetchVerificationCode();
    } catch (error) {
      console.error('Failed to submit reply:', error);
      setVerificationError(error.message);
    }
  };

  const handleVote = async (voteType) => {
    try {
      // Check if already voted
      if (manageVoteCookie(comment.comment_id, voteType)) {
        return; // Already voted on this comment
      }
      
      await updateCommentVote(comment.comment_id, voteType);
      onCommentUpdate();
    } catch (error) {
      console.error('Failed to update vote:', error);
    }
  };

  return (
    <Box sx={{ ml: level * 4, mb: 2 }}>
      <Card 
        sx={{ 
          p: 2,
          mb: 2,
          '&:hover': {
            bgcolor: (theme) => theme.palette.action.hover
          }
        }}
      >
        <Stack spacing={1}>
          <Stack 
            direction="row" 
            spacing={2} 
            alignItems="center" 
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="subtitle2" sx={{ color: 'primary.main', fontWeight: 500 }}>
                {comment.comment_name || t('watch', 'anonymous')}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {new Date(comment.comment_time * 1000).toLocaleString()}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              {level < MAX_NESTING_LEVEL && (
                <IconButton 
                  size="small" 
                  onClick={() => setShowReplyForm(!showReplyForm)}
                  sx={{ color: 'text.secondary' }}
                >
                  <ReplyIcon fontSize="small" />
                </IconButton>
              )}
              <IconButton 
                size="small"
                onClick={() => handleVote('up')}
                sx={{ color: 'success.main' }}
              >
                <ThumbUpIcon fontSize="small" />
                <Typography variant="caption" sx={{ ml: 0.5 }}>
                  {comment.comment_up || 0}
                </Typography>
              </IconButton>
              <IconButton 
                size="small"
                onClick={() => handleVote('down')}
                sx={{ color: 'error.main' }}
              >
                <ThumbDownIcon fontSize="small" />
                <Typography variant="caption" sx={{ ml: 0.5 }}>
                  {comment.comment_down || 0}
                </Typography>
              </IconButton>
            </Stack>
          </Stack>
          
          <Typography variant="body2">
            {comment.comment_content}
          </Typography>

          {showReplyForm && (
            <Box component="form" onSubmit={handleReplySubmit} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                multiline
                rows={2}
                value={replyContent}
                onChange={(e) => setReplyContent(e.target.value)}
                placeholder={t('watch', 'replyPlaceholder')}
                size="small"
                sx={{ mb: 1 }}
              />
              <Box sx={{ mb: 1 }}>
                <Box sx={{ 
                  display: 'flex', 
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 1,
                  mb: 1 
                }}>
                  <div 
                    dangerouslySetInnerHTML={{ __html: captchaSvg }}
                    onClick={fetchVerificationCode}
                    style={{ cursor: 'pointer' }}
                    title={t('watch', 'clickToRefresh')}
                  />
                </Box>
                <TextField
                  fullWidth
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  placeholder={t('watch', 'enterVerificationCode')}
                  size="small"
                  error={!!verificationError}
                  helperText={verificationError}
                />
              </Box>
              <Button
                type="submit"
                variant="contained"
                size="small"
                disabled={!replyContent.trim() || !verificationCode || loading}
              >
                {loading ? t('watch', 'submitting') : t('watch', 'reply')}
              </Button>
              <Button
                size="small"
                onClick={() => setShowReplyForm(false)}
                sx={{ ml: 1 }}
              >
                {t('watch', 'cancel')}
              </Button>
            </Box>
          )}
        </Stack>
      </Card>

      {comment.replies?.map((reply) => (
        <Comment
          key={reply.comment_id}
          comment={reply}
          level={level + 1}
          onReply={onReply}
          anonymousName={anonymousName}
          isLocalhost={isLocalhost}
          loading={loading}
          videoId={videoId}
          onCommentUpdate={onCommentUpdate}
        />
      ))}
    </Box>
  );
};

const Comments = ({ videoId }) => {
  const { t } = useLanguage();
  const { user } = useAuth();
  const [comments, setComments] = useState([]);
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [anonymousName, setAnonymousName] = useState('');
  const [page, setPage] = useState(1);
  const [hasMoreComments, setHasMoreComments] = useState(false);
  const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
  const [verificationCode, setVerificationCode] = useState('');
  const [codeId, setCodeId] = useState(null);
  const [captchaSvg, setCaptchaSvg] = useState('');

  const fetchComments = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getComments(videoId, page);
      
      if (page === 1) {
        setComments(response.comments);
      } else {
        setComments(prev => [...prev, ...response.comments]);
      }
      setHasMoreComments(response.hasMore);
    } catch (error) {
      console.error('Failed to fetch comments:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [videoId, page]);

  useEffect(() => {
    fetchComments();
  }, [videoId, page]);

  useEffect(() => {
    if (!user) {
      let name = Cookies.get('anonymousName');
      if (!name) {
        name = generateAnonymousName();
        Cookies.set('anonymousName', name, { expires: 30, path: '/', sameSite: 'Lax' });
      }
      setAnonymousName(name);
    }
  }, [user]);

  const fetchVerificationCode = useCallback(async () => {
    try {
      const response = await fetch('/api/verification-code');
      const data = await response.json();
      if (data.status === 'success') {
        setCodeId(data.data.codeId);
        setCaptchaSvg(data.data.svgData);
      }
    } catch (error) {
      console.error('Failed to fetch verification code:', error);
      setError(t('common', 'verificationCodeError'));
    }
  }, [t]);

  useEffect(() => {
    // Always fetch verification code
    fetchVerificationCode();
  }, [fetchVerificationCode]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!content.trim()) return;

    try {
      setLoading(true);
      setError(null);

      if (!verificationCode) {
        setError(t('common', 'invalidVerificationCode'));
        return;
      }

      const response = await fetch(`/api/videos/${videoId}/comments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          content,
          codeId,
          verificationCode,
          anonymousName: user ? user.username : anonymousName,
          user_id: user ? user.user_id : null
        })
      });

      const data = await response.json();
      if (data.status === 'error') throw new Error(data.message);

      setContent('');
      setVerificationCode('');
      fetchVerificationCode();
      // Refresh comments
      fetchComments();
    } catch (error) {
      console.error('Failed to add comment:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleReply = useCallback(async (parentId, content, codeId, verificationCode) => {
    try {
      setLoading(true);
      const response = await fetch(`/api/videos/${videoId}/comments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          content,
          comment_pid: parentId,
          codeId,
          verificationCode,
          anonymousName: user ? user.username : anonymousName,
          user_id: user ? user.user_id : null
        })
      });

      const data = await response.json();
      if (data.status === 'error') throw new Error(data.message);

      // Refresh comments
      fetchComments();
    } catch (error) {
      console.error('Failed to add reply:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  }, [videoId, user, anonymousName, fetchComments]);

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h6" gutterBottom>
        {t('watch', 'comments')}
      </Typography>

      <Box component="form" onSubmit={handleSubmit} sx={{ mb: 3 }}>
        <TextField
          fullWidth
          multiline
          rows={3}
          value={content}
          onChange={(e) => setContent(e.target.value)}
          placeholder={t('watch', 'writeComment')}
          error={!!error}
          helperText={error}
          sx={{ mb: 2 }}
        />
        <Box sx={{ mb: 2 }}>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center',
            gap: 1,
            mb: 1 
          }}>
            <div 
              dangerouslySetInnerHTML={{ __html: captchaSvg }}
              onClick={fetchVerificationCode}
              style={{ cursor: 'pointer' }}
              title={t('watch', 'clickToRefresh')}
            />
          </Box>
          <TextField
            fullWidth
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            placeholder={t('watch', 'enterVerificationCode')}
            size="small"
          />
        </Box>
        <Button
          type="submit"
          variant="contained"
          disabled={!content.trim() || !verificationCode || loading}
        >
          {loading ? t('watch', 'submitting') : t('watch', 'postComment')}
        </Button>
      </Box>

      {comments.length === 0 ? (
        <Box sx={{ textAlign: 'center', py: 4, color: 'text.secondary' }}>
          <Typography variant="body1">
            {t('watch', 'noComments')}
          </Typography>
        </Box>
      ) : (
        <Stack spacing={2}>
          {comments.map((comment) => (
            <Comment
              key={comment.comment_id}
              comment={comment}
              anonymousName={anonymousName}
              isLocalhost={isLocalhost}
              loading={loading}
              videoId={videoId}
              onCommentUpdate={fetchComments}
              onReply={handleReply}
            />
          ))}
        </Stack>
      )}

      {hasMoreComments && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <Button 
            variant="outlined" 
            onClick={() => setPage(prev => prev + 1)}
            disabled={loading}
            startIcon={loading && <CircularProgress size={20} />}
          >
            {loading ? t('watch', 'loading') : t('watch', 'loadMore')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Comments; 