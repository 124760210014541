import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  Typography,
  IconButton,
  useTheme,
  Zoom,
  Divider,
  Stack,
  useMediaQuery,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MovieIcon from '@mui/icons-material/Movie';
import { useLanguage } from '../contexts/LanguageContext';

const Footer = () => {
  const theme = useTheme();
  const { t } = useLanguage();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showBackToTop, setShowBackToTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setShowBackToTop(window.scrollY > 400);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleBackToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      {/* Back to Top Button */}
      <Zoom in={showBackToTop}>
        <IconButton
          onClick={handleBackToTop}
          sx={{
            position: 'fixed',
            bottom: 20,
            right: 20,
            backgroundColor: 'primary.main',
            color: 'white',
            '&:hover': {
              backgroundColor: 'primary.dark',
            },
            zIndex: 1000,
            boxShadow: theme.shadows[4],
          }}
        >
          <KeyboardArrowUpIcon />
        </IconButton>
      </Zoom>

      {/* Footer Content */}
      <Box
        component="footer"
        sx={{
          bgcolor: 'background.paper',
          borderTop: '1px solid',
          borderColor: 'divider',
          mt: { xs: 6, sm: 8, md: 10 },
          py: { xs: 3, md: 4 },
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            {/* Logo and Description */}
            <Grid item xs={12} sm={4}>
              <Stack spacing={2}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <MovieIcon
                    sx={{
                      color: 'primary.main',
                      fontSize: '1.5rem',
                    }}
                  />
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "'Inter', sans-serif",
                      fontWeight: 600,
                      color: 'primary.main',
                    }}
                  >
                    {t('common', 'appName')}
                  </Typography>
                </Box>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ maxWidth: '90%' }}
                >
                  {t('footer', 'description')}
                </Typography>
              </Stack>
            </Grid>

            {/* Quick Links */}
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle1" gutterBottom fontWeight={600}>
                {t('footer', 'quickLinks')}
              </Typography>
              <Stack spacing={1}>
                <Link
                  to="/"
                  style={{
                    textDecoration: 'none',
                    color: theme.palette.text.secondary,
                  }}
                >
                  <Typography variant="body2">{t('footer', 'home')}</Typography>
                </Link>
                <Link
                  to="/browse"
                  style={{
                    textDecoration: 'none',
                    color: theme.palette.text.secondary,
                  }}
                >
                  <Typography variant="body2">{t('footer', 'browse')}</Typography>
                </Link>
                <Link
                  to="/sitemap-index.xml"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: 'none',
                    color: theme.palette.text.secondary,
                  }}
                >
                  <Typography variant="body2">{t('footer', 'sitemap')}</Typography>
                </Link>
              </Stack>
            </Grid>

            {/* Legal */}
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle1" gutterBottom fontWeight={600}>
                {t('footer', 'legal')}
              </Typography>
              <Stack spacing={1}>
                <Link
                  to="/privacy"
                  style={{
                    textDecoration: 'none',
                    color: theme.palette.text.secondary,
                  }}
                >
                  <Typography variant="body2">
                    {t('footer', 'privacyPolicy')}
                  </Typography>
                </Link>
                <Link
                  to="/terms"
                  style={{
                    textDecoration: 'none',
                    color: theme.palette.text.secondary,
                  }}
                >
                  <Typography variant="body2">
                    {t('footer', 'termsOfService')}
                  </Typography>
                </Link>
              </Stack>
            </Grid>
          </Grid>

          <Divider sx={{ my: 3 }} />

          {/* Copyright */}
          <Typography
            variant="body2"
            color="text.secondary"
            align={isMobile ? 'center' : 'left'}
          >
            © {new Date().getFullYear()} {t('common', 'appName')}. {t('footer', 'copyright')}
          </Typography>
          
          {/* Disclaimer */}
          <Typography
            variant="caption"
            color="text.secondary"
            align="center"
            sx={{ display: 'block', mt: 1 }}
          >
            本站所有内容均来自互联网分享站点所提供的公开引用资源，未提供资源上传、下载服务。
            如有任何侵权或广告查询，欢迎电邮至admin@247kan.com
          </Typography>
        </Container>
      </Box>
    </>
  );
};

export default Footer; 