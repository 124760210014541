import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, Button, Stack, IconButton, alpha, useTheme, Grid, Snackbar } from '@mui/material';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import ShareIcon from '@mui/icons-material/Share';
import StarIcon from '@mui/icons-material/Star';
import { useLanguage } from '../contexts/LanguageContext';
import { useAuth } from '../contexts/AuthContext';
import { addToFavorites, removeFromFavorites, addToWatchLater, removeFromWatchLater, getFavorites, getWatchLater } from '../services/api';

const HeroSection = ({ featuredMovies }) => {
  const { t } = useLanguage();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated } = useAuth();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [isWatchLater, setIsWatchLater] = useState(false);
  const [favoriteLoading, setFavoriteLoading] = useState(false);
  const [watchLaterLoading, setWatchLaterLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Auto-rotate featured movies every 8 seconds
  useEffect(() => {
    if (featuredMovies?.length > 1) {
      const interval = setInterval(() => {
        setCurrentIndex((prev) => (prev + 1) % featuredMovies.length);
      }, 8000);
      return () => clearInterval(interval);
    }
  }, [featuredMovies]);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    const checkUserLists = async () => {
      if (!isAuthenticated || !featuredMovies[currentIndex]?.vod_id) return;

      try {
        const [favoritesResponse, watchLaterResponse] = await Promise.all([
          getFavorites(),
          getWatchLater()
        ]);

        const favorites = favoritesResponse.data || [];
        const watchLater = watchLaterResponse.data || [];

        setIsFavorite(favorites.some(item => item.vod_id === featuredMovies[currentIndex].vod_id));
        setIsWatchLater(watchLater.some(item => item.vod_id === featuredMovies[currentIndex].vod_id));
      } catch (error) {
        console.error('Error checking user lists:', error);
      }
    };

    checkUserLists();
  }, [isAuthenticated, featuredMovies, currentIndex]);

  const handleFavoriteClick = async () => {
    if (!isAuthenticated) {
      setSnackbarMessage(t('common', 'pleaseLoginFirst'));
      return;
    }

    if (favoriteLoading) return;

    setFavoriteLoading(true);
    try {
      if (isFavorite) {
        await removeFromFavorites(featuredMovies[currentIndex].vod_id);
        setSnackbarMessage(t('movieDetail', 'removeFromFavorites'));
      } else {
        await addToFavorites(featuredMovies[currentIndex].vod_id);
        setSnackbarMessage(t('movieDetail', 'addToFavorites'));
      }
      setIsFavorite(!isFavorite);
    } catch (error) {
      console.error('Error updating favorites:', error);
      setSnackbarMessage(error.message || t('common', 'errorOccurred'));
    } finally {
      setFavoriteLoading(false);
    }
  };

  const handleWatchLaterClick = async () => {
    if (!isAuthenticated) {
      setSnackbarMessage(t('common', 'pleaseLoginFirst'));
      return;
    }

    if (watchLaterLoading) return;

    setWatchLaterLoading(true);
    try {
      if (isWatchLater) {
        await removeFromWatchLater(featuredMovies[currentIndex].vod_id);
        setSnackbarMessage(t('member', 'removedFromWatchLater'));
      } else {
        await addToWatchLater(featuredMovies[currentIndex].vod_id);
        setSnackbarMessage(t('member', 'addedToWatchLater'));
      }
      setIsWatchLater(!isWatchLater);
    } catch (error) {
      console.error('Error updating watch later:', error);
      setSnackbarMessage(error.message || t('common', 'errorOccurred'));
    } finally {
      setWatchLaterLoading(false);
    }
  };

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: featuredMovies[currentIndex]?.vod_name,
          text: featuredMovies[currentIndex]?.vod_content,
          url: window.location.href,
        });
      } else {
        await navigator.clipboard.writeText(window.location.href);
        setSnackbarMessage(t('common', 'linkCopied'));
      }
    } catch (err) {
      console.error('Error sharing:', err);
      if (err.name !== 'AbortError') {
        setSnackbarMessage(t('common', 'errorOccurred'));
      }
    }
  };

  if (!featuredMovies?.length) return null;

  const currentMovie = featuredMovies[currentIndex] || {};

  return (
    <Box
      sx={{
        position: 'relative',
        height: { xs: '500px', sm: '600px', md: '85vh', lg: '90vh' },
        overflow: 'hidden',
        bgcolor: 'background.default',
        marginBottom: { xs: 2, sm: 3, md: 4 },
        aspectRatio: { xs: 'auto', sm: '16/9' },
        width: '100vw',
        left: '50%',
        right: '50%',
        marginLeft: '-50vw',
        marginRight: '-50vw',
      }}
    >
      {/* Background Image Container */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '100%',
            background: (theme) => `
              linear-gradient(
                0deg,
                ${alpha(theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.common.black, theme.palette.mode === 'dark' ? 0.95 : 0.85)} 0%,
                ${alpha(theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.common.black, theme.palette.mode === 'dark' ? 0.8 : 0.7)} 10%,
                ${alpha(theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.common.black, theme.palette.mode === 'dark' ? 0.6 : 0.5)} 20%,
                ${alpha(theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.common.black, theme.palette.mode === 'dark' ? 0.4 : 0.3)} 30%,
                transparent 60%
              ),
              linear-gradient(
                90deg,
                ${alpha(theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.common.black, theme.palette.mode === 'dark' ? 0.8 : 0.7)} 0%,
                ${alpha(theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.common.black, theme.palette.mode === 'dark' ? 0.6 : 0.5)} 20%,
                ${alpha(theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.common.black, theme.palette.mode === 'dark' ? 0.2 : 0.2)} 40%,
                transparent 60%
              )
            `,
            pointerEvents: 'none',
            zIndex: 1,
          },
        }}
      >
        {/* Background Image with Ken Burns effect */}
        <Box
          component="img"
          src={currentMovie?.vod_tmdb_backdrop || currentMovie?.vod_pic || '/placeholder.jpg'}
          alt={currentMovie?.vod_name || 'Movie poster'}
          onError={(e) => {
            setImageError(true);
            e.target.src = currentMovie?.vod_pic || '/placeholder.jpg';
            e.target.style.objectFit = 'contain';
            e.target.style.background = 'rgba(0,0,0,0.8)';
          }}
          sx={{
            minWidth: '100%',
            minHeight: '100%',
            width: 'auto',
            height: 'auto',
            objectFit: imageError ? 'contain' : 'cover',
            objectPosition: 'center',
            animation: imageError ? 'none' : 'kenBurns 30s ease-in-out infinite alternate',
            '@keyframes kenBurns': {
              '0%': {
                transform: 'scale(1.02) translate(0, 0)',
                filter: 'brightness(1.1)',
              },
              '100%': {
                transform: 'scale(1.08) translate(-1%, -1%)',
                filter: 'brightness(1.2)',
              },
            },
            transition: 'all 0.3s ease-in-out',
            background: imageError ? 'rgba(0,0,0,0.8)' : 'none',
          }}
        />
      </Box>

      {/* Content Overlay */}
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'flex-end',
          pb: { xs: 6, sm: 8, md: 12 },
          px: { xs: 2, sm: 3, md: 4 },
          position: 'relative',
          zIndex: 3,
          maxWidth: theme.breakpoints.values.xl,
          mx: 'auto',
          width: '100%',
        }}
      >
        <Box maxWidth="lg" sx={{ width: '100%' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8} lg={7}>
              <Stack spacing={3}>
                {/* Title */}
                <Typography
                  variant="h1"
                  sx={{
                    color: 'common.white',
                    fontSize: { xs: '1.75rem', sm: '2.5rem', md: '3.5rem', lg: '4rem' },
                    fontWeight: 800,
                    letterSpacing: '-0.02em',
                    lineHeight: 1.1,
                    textShadow: '0 2px 4px rgba(0,0,0,0.4)',
                    mb: { xs: 1, sm: 2 },
                  }}
                >
                  {currentMovie.vod_name}
                </Typography>

                {/* Description */}
                <Typography
                  variant="body1"
                  sx={{
                    color: 'grey.100',
                    fontSize: { xs: '0.875rem', sm: '1rem', md: '1.1rem' },
                    lineHeight: 1.5,
                    maxWidth: '700px',
                    mb: { xs: 2, sm: 3 },
                    opacity: 0.9,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: { xs: 2, sm: 3 },
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  {currentMovie.vod_content?.replace(/<[^>]+>/g, '').trim()}
                </Typography>

                {/* Action Buttons */}
                <Stack
                  direction="row"
                  spacing={{ xs: 1.5, sm: 2 }}
                  alignItems="center"
                  flexWrap="wrap"
                  gap={{ xs: 1.5, sm: 2 }}
                >
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1.5, sm: 2 }} sx={{ width: { xs: '100%', sm: 'auto' } }}>
                    <Button
                      variant="contained"
                      size="large"
                      startIcon={<PlayArrowIcon />}
                      component={Link}
                      to={`/watch/${currentMovie.vod_id}`}
                      sx={{
                        px: { xs: 2.5, sm: 3, md: 4 },
                        py: { xs: 1, md: 1.5 },
                        minHeight: { xs: 40, sm: 'auto' },
                        fontSize: { xs: '0.875rem', sm: '1rem' },
                        fontWeight: 600,
                        bgcolor: 'common.white',
                        color: 'common.black',
                        width: { xs: '100%', sm: 'auto' },
                        '&:hover': {
                          bgcolor: alpha(theme.palette.common.white, 0.85),
                          transform: 'scale(1.02)',
                        },
                        transition: theme.transitions.create(['transform', 'background-color'], {
                          duration: theme.transitions.duration.shorter,
                        }),
                      }}
                    >
                      {t('home', 'watchNow')}
                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      startIcon={<InfoOutlinedIcon />}
                      component={Link}
                      to={`/detail/${currentMovie.vod_id}`}
                      sx={{
                        px: { xs: 1.5, sm: 2, md: 3 },
                        py: { xs: 1, md: 1.5 },
                        minHeight: { xs: 40, sm: 'auto' },
                        fontSize: { xs: '0.875rem', sm: '1rem' },
                        fontWeight: 600,
                        bgcolor: alpha(theme.palette.common.black, 0.5),
                        color: 'common.white',
                        width: { xs: '100%', sm: 'auto' },
                        '&:hover': {
                          bgcolor: alpha(theme.palette.common.black, 0.7),
                          transform: 'scale(1.02)',
                        },
                        transition: theme.transitions.create(['transform', 'background-color'], {
                          duration: theme.transitions.duration.shorter,
                        }),
                      }}
                    >
                      {t('home', 'moreInfo')}
                    </Button>
                  </Stack>

                  {/* Mobile badges and action buttons */}
                  <Stack 
                    direction="row"
                    spacing={1.5}
                    alignItems="center"
                    sx={{
                      width: '100%',
                      mt: { xs: 0, sm: 0 },
                      bgcolor: { xs: alpha(theme.palette.common.black, 0.3), sm: 'transparent' },
                      borderRadius: { xs: 1, sm: 0 },
                      p: { xs: 1, sm: 0 },
                      display: 'flex',
                      overflow: 'hidden',
                    }}
                  >
                    {/* Badges */}
                    <Stack
                      direction="row"
                      spacing={1}
                      alignItems="center"
                      sx={{
                        overflow: 'auto',
                        flexWrap: 'nowrap',
                        flex: '1 0 auto',
                        msOverflowStyle: 'none',
                        scrollbarWidth: 'none',
                        '&::-webkit-scrollbar': {
                          display: 'none'
                        },
                        '& > *': {
                          flex: '0 0 auto'
                        }
                      }}
                    >
                      {currentMovie.vod_year && (
                        <Typography
                          variant="subtitle1"
                          sx={{
                            color: (theme) => theme.palette.mode === 'dark' ? 'text.secondary' : 'common.white',
                            fontWeight: 500,
                            bgcolor: (theme) => alpha(
                              theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.common.black,
                              theme.palette.mode === 'dark' ? 0.3 : 0.5
                            ),
                            px: { xs: 1, sm: 1.5 },
                            py: { xs: 0.3, sm: 0.5 },
                            borderRadius: 1,
                            fontSize: { xs: '0.8rem', sm: '0.9rem' },
                            whiteSpace: 'nowrap',
                            display: 'inline-flex',
                          }}
                        >
                          {currentMovie.vod_year}
                        </Typography>
                      )}
                      {currentMovie.vod_remarks && (
                        <Typography
                          variant="subtitle1"
                          sx={{
                            color: (theme) => theme.palette.mode === 'dark' ? 'text.secondary' : 'common.white',
                            fontWeight: 500,
                            bgcolor: (theme) => alpha(
                              theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.common.black,
                              theme.palette.mode === 'dark' ? 0.3 : 0.5
                            ),
                            px: { xs: 1, sm: 1.5 },
                            py: { xs: 0.3, sm: 0.5 },
                            borderRadius: 1,
                            fontSize: { xs: '0.8rem', sm: '0.9rem' },
                            whiteSpace: 'nowrap',
                            display: 'inline-flex',
                          }}
                        >
                          {currentMovie.vod_remarks}
                        </Typography>
                      )}
                      {currentMovie.vod_score !== "0" && currentMovie.vod_score !== 0 && parseFloat(currentMovie.vod_score) > 0 && (
                        <Box
                          sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            bgcolor: (theme) => alpha(
                              theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.common.black,
                              theme.palette.mode === 'dark' ? 0.3 : 0.5
                            ),
                            px: { xs: 1, sm: 1.5 },
                            py: { xs: 0.3, sm: 0.5 },
                            borderRadius: 1,
                            whiteSpace: 'nowrap',
                          }}
                        >
                          <StarIcon sx={{ color: 'warning.main', fontSize: { xs: '1rem', sm: '1.2rem' }, mr: 0.5 }} />
                          <Typography
                            variant="subtitle1"
                            sx={{
                              color: 'warning.main',
                              fontWeight: 600,
                              fontSize: { xs: '0.8rem', sm: '0.9rem' },
                              display: 'inline-flex',
                            }}
                          >
                            {parseFloat(currentMovie.vod_score).toFixed(1)}
                          </Typography>
                        </Box>
                      )}
                    </Stack>

                    {/* Like and Share buttons */}
                    <Stack 
                      direction="row" 
                      spacing={1.5}
                      sx={{
                        flex: '0 0 auto'
                      }}
                    >
                      <IconButton
                        onClick={handleFavoriteClick}
                        disabled={favoriteLoading}
                        sx={{
                          color: (theme) => theme.palette.mode === 'dark' ? 'text.primary' : 'common.white',
                          bgcolor: (theme) => alpha(
                            theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.common.black,
                            theme.palette.mode === 'dark' ? 0.3 : 0.5
                          ),
                          width: { xs: 36, md: 40 },
                          height: { xs: 36, md: 40 },
                          '&:hover': {
                            bgcolor: (theme) => alpha(
                              theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.common.black,
                              theme.palette.mode === 'dark' ? 0.4 : 0.6
                            ),
                            transform: 'scale(1.1)',
                          },
                        }}
                      >
                        {isFavorite ? 
                          <FavoriteIcon sx={{ fontSize: { xs: '1.2rem', md: '1.4rem' } }} /> : 
                          <FavoriteBorderIcon sx={{ fontSize: { xs: '1.2rem', md: '1.4rem' } }} />
                        }
                      </IconButton>
                      <IconButton
                        onClick={handleWatchLaterClick}
                        disabled={watchLaterLoading}
                        sx={{
                          color: (theme) => theme.palette.mode === 'dark' ? 'text.primary' : 'common.white',
                          bgcolor: (theme) => alpha(
                            theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.common.black,
                            theme.palette.mode === 'dark' ? 0.3 : 0.5
                          ),
                          width: { xs: 36, md: 40 },
                          height: { xs: 36, md: 40 },
                          '&:hover': {
                            bgcolor: (theme) => alpha(
                              theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.common.black,
                              theme.palette.mode === 'dark' ? 0.4 : 0.6
                            ),
                            transform: 'scale(1.1)',
                          },
                        }}
                      >
                        {isWatchLater ? 
                          <WatchLaterIcon sx={{ fontSize: { xs: '1.2rem', md: '1.4rem' } }} /> : 
                          <WatchLaterOutlinedIcon sx={{ fontSize: { xs: '1.2rem', md: '1.4rem' } }} />
                        }
                      </IconButton>
                      <IconButton
                        onClick={handleShare}
                        sx={{
                          color: (theme) => theme.palette.mode === 'dark' ? 'text.primary' : 'common.white',
                          bgcolor: (theme) => alpha(
                            theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.common.black,
                            theme.palette.mode === 'dark' ? 0.3 : 0.5
                          ),
                          width: { xs: 36, md: 40 },
                          height: { xs: 36, md: 40 },
                          '&:hover': {
                            bgcolor: (theme) => alpha(
                              theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.common.black,
                              theme.palette.mode === 'dark' ? 0.4 : 0.6
                            ),
                            transform: 'scale(1.1)',
                          },
                        }}
                      >
                        <ShareIcon sx={{ fontSize: { xs: '1.2rem', md: '1.4rem' } }} />
                      </IconButton>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Movie Indicators */}
      {featuredMovies.length > 1 && (
        <Stack
          direction="row"
          spacing={1.5}
          sx={{
            position: 'absolute',
            bottom: { xs: 16, sm: 20 },
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 4,
          }}
        >
          {featuredMovies.map((_, index) => (
            <Box
              key={index}
              sx={{
                width: { xs: 20, sm: 24 },
                height: { xs: 3, sm: 4 },
                borderRadius: 2,
                bgcolor: index === currentIndex ? 'primary.main' : alpha(theme.palette.common.white, 0.3),
                transition: 'all 0.3s ease',
                cursor: 'pointer',
                '&:hover': {
                  transform: 'scale(1.1)',
                },
              }}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </Stack>
      )}

      {/* Add Snackbar for notifications */}
      <Snackbar
        open={Boolean(snackbarMessage)}
        autoHideDuration={3000}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{
          bottom: { xs: '24px', sm: '32px' },
          width: { xs: 'calc(100% - 32px)', sm: 'auto' },
          maxWidth: { xs: '100%', sm: '400px' }
        }}
        ContentProps={{
          sx: {
            bgcolor: theme => snackbarMessage === t('common', 'pleaseLoginFirst') 
              ? alpha(theme.palette.error.main, theme.palette.mode === 'dark' ? 0.9 : 0.95)
              : theme.palette.mode === 'dark' 
                ? alpha(theme.palette.primary.main, 0.85)
                : alpha(theme.palette.primary.main, 0.9),
            color: 'common.white',
            fontWeight: { xs: 400, sm: 500 },
            fontSize: { xs: '0.9375rem', sm: '1rem' },
            px: { xs: 2, sm: 3 },
            py: { xs: 1.25, sm: 1.5 },
            borderRadius: { xs: 1.5, sm: 2 },
            backdropFilter: 'blur(8px)',
            border: 1,
            borderColor: theme => alpha(theme.palette.common.white, theme.palette.mode === 'dark' ? 0.1 : 0.15),
            boxShadow: theme => theme.palette.mode === 'dark'
              ? `0 4px 20px ${alpha('#000', 0.4)}`
              : `0 4px 16px ${alpha(theme.palette.primary.main, 0.16)}`,
            '& .MuiSnackbarContent-message': {
              textAlign: 'center',
              width: '100%',
              lineHeight: 1.5
            }
          }
        }}
      />
    </Box>
  );
};

export default HeroSection; 