// Casting Service for AirPlay, Chromecast, and Miracast functionality

class CastingService {
    constructor() {
        this.castSession = null;
        this.isAvailable = false;
        this.airPlayAvailable = false;
        this.miracastAvailable = false;
        this.initializeAirPlay();
        this.initializeChromecast();
        this.initializeMiracast();
        this.initRetries = 0;
        this.maxRetries = 3;
    }

    // Retry helper function
    async retryOperation(operation, maxRetries = 3, delay = 1000) {
        let lastError;
        for (let i = 0; i < maxRetries; i++) {
            try {
                return await operation();
            } catch (error) {
                lastError = error;
                if (i < maxRetries - 1) {
                    console.log(`Retrying operation (attempt ${i + 2}/${maxRetries})...`);
                    await new Promise(resolve => setTimeout(resolve, delay));
                }
            }
        }
        throw lastError;
    }

    // Initialize Chromecast
    initializeChromecast() {
        if (typeof window.chrome === 'undefined') {
            window.chrome = {};
        }

        if (!document.querySelector('script[src*="cast_sender"]')) {
            const script = document.createElement('script');
            script.src = 'https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1';
            document.head.appendChild(script);
        }

        if (window.chrome.cast && window.chrome.cast.isAvailable) {
            this.initializeCastApi();
        } else {
            window['__onGCastApiAvailable'] = (isAvailable) => {
                if (isAvailable) {
                    this.initializeCastApi();
                } else {
                    console.warn('Google Cast API is not available');
                    this.retryOperation(() => this.initializeChromecast());
                }
            };
        }
    }

    // Initialize Cast API
    initializeCastApi() {
        const applicationID = window.chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID;
        
        try {
            const sessionRequest = new window.chrome.cast.SessionRequest(applicationID);
            const apiConfig = new window.chrome.cast.ApiConfig(
                sessionRequest,
                (session) => this.sessionListener(session),
                (availability) => this.receiverListener(availability),
                window.chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED
            );

            window.chrome.cast.initialize(
                apiConfig,
                () => {
                    console.log('Chromecast initialized successfully');
                    this.isAvailable = true;
                    this.initRetries = 0;
                },
                (error) => {
                    console.error('Chromecast initialization error:', error);
                    this.retryOperation(() => this.initializeCastApi());
                }
            );
        } catch (error) {
            console.error('Error during Cast API initialization:', error);
            this.handleError(error);
        }
    }

    // Session Listener
    sessionListener(session) {
        this.castSession = session;
        if (this.onSessionConnected) {
            this.onSessionConnected(session);
        }
    }

    // Receiver Listener
    receiverListener(availability) {
        if (availability === window.chrome.cast.ReceiverAvailability.AVAILABLE) {
            console.log('Chromecast receivers found');
        }
    }

    // Start Casting
    async startCasting(mediaUrl, mediaInfo = {}) {
        if (!this.isAvailable) {
            throw new Error('Chromecast is not initialized yet. Please try again in a moment.');
        }

        if (!window.chrome || !window.chrome.cast) {
            throw new Error('Chrome Cast API not available');
        }

        try {
            // If there's an existing session, stop it first
            if (this.castSession) {
                await this.stopCasting().catch(console.warn);
            }

            // Create new session
            this.castSession = await this.retryOperation(async () => {
                return new Promise((resolve, reject) => {
                    window.chrome.cast.requestSession(
                        session => resolve(session),
                        error => reject(new Error(error.description || 'Failed to create cast session'))
                    );
                });
            });

            // Use the original URL without converting to blob
            const castMediaInfo = new window.chrome.cast.media.MediaInfo(mediaUrl, 'application/x-mpegURL');
            
            // Set metadata based on content type
            const metadata = new window.chrome.cast.media.TvShowMediaMetadata();
            metadata.title = mediaInfo.title || '';
            metadata.subtitle = mediaInfo.subtitle || '';
            metadata.episode = mediaInfo.episode || '';
            metadata.originalAirDate = new Date().toISOString();
            
            // Configure media info
            castMediaInfo.metadata = metadata;
            castMediaInfo.streamType = window.chrome.cast.media.StreamType.LIVE;
            
            // Add necessary headers and CORS settings
            castMediaInfo.customData = {
                cors: ['*'],
                withCredentials: false,
                headers: {
                    'Origin': '*',
                    'Referer': '*'
                }
            };

            // Configure the load request
            const request = new window.chrome.cast.media.LoadRequest(castMediaInfo);
            request.autoplay = true;
            request.currentTime = mediaInfo.currentTime || 0;

            // Load media using callback-based API with enhanced error handling
            const media = await new Promise((resolve, reject) => {
                try {
                    console.log('Attempting to cast URL:', mediaUrl);
                    this.castSession.loadMedia(
                        request,
                        mediaSession => {
                            if (!mediaSession) {
                                reject(new Error('Failed to load media: Media session is undefined'));
                                return;
                            }
                            console.log('Media loaded successfully:', mediaSession);
                            resolve(mediaSession);
                        },
                        error => {
                            console.error('Detailed casting error:', error);
                            reject(new Error(`Failed to load media: ${error.description || error.message || 'Unknown error'}`));
                        }
                    );
                } catch (error) {
                    console.error('Error during loadMedia:', error);
                    reject(new Error(`Failed to load media: ${error.message || 'Unknown error'}`));
                }
            });

            // Add media status listener
            if (media && typeof media.addUpdateListener === 'function') {
                media.addUpdateListener((isAlive) => {
                    if (!isAlive) {
                        this.handleError(new Error('Media session ended unexpectedly'));
                    }
                });
            }

            return media;
        } catch (error) {
            console.error('Casting error:', error);
            this.handleError(error);
            throw error;
        }
    }

    handleError(error) {
        console.error('Casting error:', error);
        const errorMessage = error.description || error.message || 'Unknown casting error';
        if (this.onError) {
            this.onError(new Error(errorMessage));
        }
    }

    // Add error callback setter
    setErrorCallback(callback) {
        this.onError = callback;
    }

    // Stop Casting
    async stopCasting() {
        if (this.castSession) {
            try {
                await new Promise((resolve, reject) => {
                    this.castSession.stop(resolve, reject);
                });
                this.castSession = null;
            } catch (error) {
                console.error('Error stopping cast session:', error);
                throw error;
            }
        }
    }

    // Initialize AirPlay
    initializeAirPlay() {
        if ('WebKitPlaybackTargetAvailabilityEvent' in window) {
            const video = document.createElement('video');
            video.addEventListener('webkitplaybacktargetavailabilitychanged', (event) => {
                if (event.availability === 'available') {
                    this.airPlayAvailable = true;
                }
            });
        }
    }

    // Start AirPlay
    startAirPlay(videoElement) {
        if (videoElement && videoElement.webkitShowPlaybackTargetPicker) {
            videoElement.webkitShowPlaybackTargetPicker();
        } else {
            console.warn('AirPlay is not supported on this device/browser');
        }
    }

    // Initialize Miracast
    initializeMiracast() {
        if ('getScreens' in window) {
            navigator.mediaDevices.getScreens()
                .then(screens => {
                    this.miracastAvailable = screens.some(screen => screen.type === 'miracast');
                })
                .catch(error => {
                    console.warn('Miracast detection failed:', error);
                    this.miracastAvailable = false;
                });
        } else if ('presentation' in navigator && 'defaultRequest' in navigator.presentation) {
            // Modern Presentation API support
            this.miracastAvailable = true;
        }
    }

    // Start Miracast
    async startMiracast(mediaUrl, mediaInfo = {}) {
        if (!this.miracastAvailable) {
            throw new Error('Miracast is not available on this device');
        }

        try {
            if ('presentation' in navigator) {
                const presentationUrl = new URL(mediaUrl);
                const presentationRequest = new PresentationRequest([presentationUrl.toString()]);
                
                // Start the presentation
                const connection = await presentationRequest.start();
                
                connection.addEventListener('connect', () => {
                    console.log('Miracast connection established');
                });

                connection.addEventListener('close', () => {
                    console.log('Miracast connection closed');
                });

                connection.addEventListener('terminate', () => {
                    console.log('Miracast connection terminated');
                });

                // Send media information to the presentation
                connection.send({
                    type: 'media',
                    url: mediaUrl,
                    title: mediaInfo.title,
                    currentTime: mediaInfo.currentTime || 0
                });

                return connection;
            } else {
                throw new Error('Presentation API not supported');
            }
        } catch (error) {
            console.error('Miracast error:', error);
            this.handleError(error);
            throw error;
        }
    }

    // Check if casting is available
    isCastingAvailable() {
        return this.isAvailable || this.airPlayAvailable || this.miracastAvailable;
    }

    // Set session connected callback
    setSessionConnectedCallback(callback) {
        this.onSessionConnected = callback;
    }
}

// Create and export a singleton instance
const castingService = new CastingService();
export default castingService; 