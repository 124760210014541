import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  InputBase,
  Box,
  Menu,
  MenuItem,
  Stack,
  Divider,
  ListItemIcon,
  ListItemText,
  Switch,
  Tooltip,
  useTheme,
  useMediaQuery,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  Collapse,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import MovieIcon from '@mui/icons-material/Movie';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import TranslateIcon from '@mui/icons-material/Translate';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useThemeContext } from '../contexts/ThemeContext';
import { useLanguage } from '../contexts/LanguageContext';
import HistoryWatched from './HistoryWatched';
import ChatIcon from '@mui/icons-material/Chat';
import Paper from '@mui/material/Paper';
import LocalMoviesIcon from '@mui/icons-material/LocalMovies';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import CampaignIcon from '@mui/icons-material/Campaign';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useAuth } from '../contexts/AuthContext';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.mode === 'light' ? '#000000' : '#ffffff', 0.1),
  '&:hover': {
    backgroundColor: alpha(theme.palette.mode === 'light' ? '#000000' : '#ffffff', 0.15),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  display: 'flex',
  alignItems: 'center',
  flex: '0 1 auto',
  minWidth: 'auto',
  [theme.breakpoints.down('md')]: {
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    maxWidth: '200px',
  },
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
      '&:focus': {
        width: '25ch',
      },
    },
  },
}));

const Navbar = ({ announcementRef }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [langAnchorEl, setLangAnchorEl] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const { colorMode } = useThemeContext();
  const { language, setLanguage, t, availableLanguages } = useLanguage();
  const [categories, setCategories] = useState([]);
  const [categoryMenuAnchor, setCategoryMenuAnchor] = useState(null);
  const [activeCategory, setActiveCategory] = useState(null);
  const [showTrending, setShowTrending] = useState(false);
  const [trendingItems, setTrendingItems] = useState([]);
  const searchRef = useRef(null);
  const { user, isAuthenticated, logout } = useAuth();
  const [userMenuAnchor, setUserMenuAnchor] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch('/api/categories');
        const result = await response.json();
        if (result.status === 'success') {
          setCategories(result.data);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchTrending = async () => {
      try {
        const response = await fetch(`/api/trending?language=${language}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const text = await response.text();
        try {
          const result = JSON.parse(text);
          if (result.status === 'success') {
            setTrendingItems(result.data.slice(0, 6)); // Show top 6 trending items
          }
        } catch (parseError) {
          console.error('Error parsing trending response:', text);
          throw parseError;
        }
      } catch (error) {
        console.error('Error fetching trending:', error);
      }
    };
    fetchTrending();
  }, [language]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowTrending(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLangMenu = (event) => {
    setLangAnchorEl(event.currentTarget);
  };

  const handleLangClose = () => {
    setLangAnchorEl(null);
  };

  const handleSearch = (e) => {
    if (e.key === 'Enter' && searchQuery.trim()) {
      navigate(`/search?q=${encodeURIComponent(searchQuery)}`);
      setSearchQuery('');
    }
  };

  const handleCategoryMouseEnter = (event, category) => {
    if (!isMobile && category.subcategories?.length > 0) {
      setCategoryMenuAnchor(event.currentTarget);
      setActiveCategory(category);
    }
  };

  const handleCategoryMouseLeave = () => {
    if (!isMobile) {
      setCategoryMenuAnchor(null);
      setActiveCategory(null);
    }
  };

  const handleCategoryClick = (category) => {
    if (category.subcategories?.length > 0) {
      setActiveCategory(activeCategory?.type_id === category.type_id ? null : category);
    }
  };

  const handleSubCategoryClick = (subcategory) => {
    navigate(`/browse?category=${subcategory.type_id}`);
    setCategoryMenuAnchor(null);
    setActiveCategory(null);
    if (isMobile) {
      setMobileOpen(false);
    }
  };

  const commonButtonProps = {
    sx: {
      color: 'text.navbar',
      '&:hover': {
        backgroundColor: alpha(theme.palette.mode === 'light' ? '#000000' : '#ffffff', 0.1),
      },
    },
  };

  const commonIconButtonProps = {
    sx: {
      color: 'text.navbar',
      '&:hover': {
        backgroundColor: alpha(theme.palette.mode === 'light' ? '#000000' : '#ffffff', 0.1),
      },
    },
  };

  const handleUserMenu = (event) => {
    setUserMenuAnchor(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setUserMenuAnchor(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      handleUserMenuClose();
      navigate('/');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  // Desktop category menu
  const renderDesktopCategories = () => (
    <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
      {categories.map((category) => (
        <Box
          key={category.type_id}
          sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}
        >
          <Button
            color="inherit"
            component={Link}
            to={`/browse?category=${category.type_id}`}
            sx={{ 
              textTransform: 'none',
              px: 2,
              minHeight: 64,
              fontSize: '1rem',
              color: activeCategory?.type_id === category.type_id 
                ? 'primary.main' 
                : theme.palette.mode === 'light' ? 'text.primary' : 'inherit',
              fontWeight: theme.palette.mode === 'light' ? 500 : 400,
              '&:hover': {
                color: 'primary.main',
                backgroundColor: 'transparent',
              },
            }}
          >
            {t('categories', category.type_name.replace('categories.', ''))}
          </Button>
          
          {category.subcategories?.length > 0 && (
            <Box
              onMouseEnter={(e) => handleCategoryMouseEnter(e, category)}
              onMouseLeave={handleCategoryMouseLeave}
            >
              <IconButton
                size="small"
                sx={{ 
                  ml: -1,
                  '&:hover': { backgroundColor: 'transparent' }
                }}
              >
                <KeyboardArrowDownIcon />
              </IconButton>
              
              <Menu
                anchorEl={categoryMenuAnchor}
                open={Boolean(categoryMenuAnchor && activeCategory?.type_id === category.type_id)}
                onClose={() => setCategoryMenuAnchor(null)}
                MenuListProps={{
                  onMouseLeave: handleCategoryMouseLeave,
                  'aria-labelledby': 'category-button',
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                PaperProps={{
                  elevation: 3,
                  sx: {
                    mt: 0.5,
                    ml: -2,
                    minWidth: 180,
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                    '& .MuiMenuItem-root': {
                      fontSize: '0.95rem',
                      py: 1,
                    },
                  },
                }}
                slotProps={{
                  paper: {
                    sx: {
                      overflow: 'visible'
                    }
                  }
                }}
              >
                {category.subcategories?.map((subcategory) => (
                  <MenuItem
                    key={subcategory.type_id}
                    onClick={() => handleSubCategoryClick(subcategory)}
                    sx={{
                      '&:hover': {
                        color: 'primary.main',
                        backgroundColor: 'action.hover',
                      },
                    }}
                  >
                    {t('categories', subcategory.type_name.replace('categories.', ''))}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );

  // Mobile category menu
  const renderMobileCategories = () => (
    <Box>
      <List component="nav" sx={{ 
        width: '100%', 
        bgcolor: 'background.paper',
        '& .MuiListItemButton-root': {
          py: 1.5,
          minHeight: 48,
        },
      }}>
        {categories.map((category) => (
          <React.Fragment key={category.type_id}>
            <ListItemButton
              onClick={() => handleCategoryClick(category)}
              sx={{
                py: 1.5,
                borderBottom: '1px solid',
                borderColor: 'divider',
                '&:active': {
                  backgroundColor: alpha(theme.palette.primary.main, 0.12),
                },
              }}
            >
              <ListItemText 
                primary={t('categories', category.type_name.replace('categories.', ''))}
                primaryTypographyProps={{
                  fontSize: '1rem',
                  fontWeight: activeCategory?.type_id === category.type_id ? 600 : 400,
                  color: activeCategory?.type_id === category.type_id ? 'primary.main' : 'text.primary',
                }}
              />
              {category.subcategories?.length > 0 && (
                <KeyboardArrowDownIcon
                  sx={{
                    transform: activeCategory?.type_id === category.type_id ? 'rotate(-180deg)' : 'rotate(0)',
                    transition: '0.3s',
                    color: activeCategory?.type_id === category.type_id ? 'primary.main' : 'text.primary',
                  }}
                />
              )}
            </ListItemButton>
            {category.subcategories?.length > 0 && (
              <Collapse 
                in={activeCategory?.type_id === category.type_id} 
                timeout="auto" 
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {category.subcategories?.map((subcategory) => (
                    <ListItemButton
                      key={subcategory.type_id}
                      onClick={() => handleSubCategoryClick(subcategory)}
                      sx={{
                        pl: 4,
                        py: 1.5,
                        backgroundColor: alpha(theme.palette.primary.main, 0.04),
                        '&:hover': {
                          backgroundColor: alpha(theme.palette.primary.main, 0.08),
                        },
                        '&:active': {
                          backgroundColor: alpha(theme.palette.primary.main, 0.12),
                        },
                      }}
                    >
                      <ListItemText 
                        primary={t('categories', subcategory.type_name.replace('categories.', ''))}
                        primaryTypographyProps={{
                          fontSize: '0.95rem',
                        }}
                      />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );

  const renderMobileUserMenu = () => (
    <>
      <Divider sx={{ my: 1 }} />
      {isAuthenticated ? (
        <>
          <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to="/member"
              onClick={() => setMobileOpen(false)}
              sx={{
                pl: 2,
                py: 1.5,
                color: theme.palette.mode === 'light' ? 'text.primary' : 'inherit',
              }}
            >
              <ListItemIcon>
                <AccountCircleIcon sx={{ color: 'inherit' }} />
              </ListItemIcon>
              <ListItemText 
                primary={t('nav', 'memberPortal')}
                secondary={user?.username}
                primaryTypographyProps={{
                  sx: { fontWeight: theme.palette.mode === 'light' ? 500 : 400 }
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={handleLogout}
              sx={{
                pl: 2,
                py: 1.5,
                color: theme.palette.mode === 'light' ? 'text.primary' : 'inherit',
              }}
            >
              <ListItemIcon>
                <LogoutIcon sx={{ color: 'inherit' }} />
              </ListItemIcon>
              <ListItemText 
                primary={t('common', 'logout')}
                primaryTypographyProps={{
                  sx: { fontWeight: theme.palette.mode === 'light' ? 500 : 400 }
                }}
              />
            </ListItemButton>
          </ListItem>
        </>
      ) : (
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/login"
            onClick={() => setMobileOpen(false)}
            sx={{
              pl: 2,
              py: 1.5,
              color: theme.palette.mode === 'light' ? 'text.primary' : 'inherit',
            }}
          >
            <ListItemIcon>
              <AccountCircleIcon sx={{ color: 'inherit' }} />
            </ListItemIcon>
            <ListItemText 
              primary={t('nav', 'memberPortal')}
              primaryTypographyProps={{
                sx: { fontWeight: theme.palette.mode === 'light' ? 500 : 400 }
              }}
            />
          </ListItemButton>
        </ListItem>
      )}
    </>
  );

  const renderMobileMenu = () => (
    <Box sx={{ width: 240 }}>
      <List>
        {/* Logo/Home link with icon and slogan */}
        <ListItem 
          disablePadding 
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            flexDirection: 'column',
            alignItems: 'flex-start',
            py: 2
          }}
        >
          <ListItemButton
            component={Link}
            to="/"
            sx={{
              width: '100%',
              py: 1
            }}
          >
            <Stack 
              direction="row" 
              spacing={1} 
              alignItems="center" 
              sx={{ width: '100%' }}
            >
              <MovieIcon 
                sx={{ 
                  color: 'primary.main',
                  fontSize: '1.5rem'
                }} 
              />
              <Typography
                variant="h6"
                component="div"
                sx={{
                  fontWeight: 700,
                  color: 'primary.main'
                }}
              >
                247看
              </Typography>
            </Stack>
          </ListItemButton>
          <Typography
            variant="caption"
            sx={{
              px: 2,
              color: 'text.secondary',
              fontStyle: 'italic'
            }}
          >
            随时随地，想看就看
          </Typography>
        </ListItem>

        {/* Categories */}
        {categories.map((category) => (
          <React.Fragment key={category.type_id}>
            <ListItem 
              disablePadding 
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
              }}
            >
              <Box sx={{ 
                display: 'flex',
                width: '100%',
                borderBottom: '1px solid',
                borderColor: 'divider',
              }}>
                {/* Navigation Button */}
                <ListItemButton
                  component={Link}
                  to={`/browse?category=${category.type_id}`}
                  onClick={() => {
                    setMobileOpen(false);
                  }}
                  sx={{
                    flex: 1,
                    py: 1.5,
                    color: theme.palette.mode === 'light' ? 'text.primary' : 'inherit',
                    fontWeight: theme.palette.mode === 'light' ? 500 : 400,
                  }}
                >
                  <ListItemText 
                    primary={t('categories', category.type_name.replace('categories.', ''))}
                    primaryTypographyProps={{
                      fontSize: '1rem',
                    }}
                  />
                </ListItemButton>

                {/* Expand Button - Only show if has subcategories */}
                {category.subcategories?.length > 0 && (
                  <ListItemButton
                    onClick={() => handleCategoryClick(category)}
                    sx={{
                      py: 1.5,
                      minWidth: '50px',
                      justifyContent: 'center',
                      borderLeft: '1px solid',
                      borderColor: 'divider',
                    }}
                  >
                    <KeyboardArrowDownIcon
                      sx={{
                        transform: activeCategory?.type_id === category.type_id ? 'rotate(-180deg)' : 'rotate(0)',
                        transition: '0.3s',
                        color: activeCategory?.type_id === category.type_id ? 'primary.main' : 'text.primary',
                      }}
                    />
                  </ListItemButton>
                )}
              </Box>

              {/* Subcategories Collapse */}
              {category.subcategories?.length > 0 && (
                <Collapse 
                  in={activeCategory?.type_id === category.type_id} 
                  timeout="auto" 
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {category.subcategories?.map((subcategory) => (
                      <ListItemButton
                        key={subcategory.type_id}
                        onClick={() => handleSubCategoryClick(subcategory)}
                        sx={{
                          pl: 4,
                          py: 1.5,
                          backgroundColor: alpha(theme.palette.primary.main, 0.04),
                          '&:hover': {
                            backgroundColor: alpha(theme.palette.primary.main, 0.08),
                          },
                          '&:active': {
                            backgroundColor: alpha(theme.palette.primary.main, 0.12),
                          },
                        }}
                      >
                        <ListItemText 
                          primary={t('categories', subcategory.type_name.replace('categories.', ''))}
                          primaryTypographyProps={{
                            fontSize: '0.95rem',
                          }}
                        />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              )}
            </ListItem>
          </React.Fragment>
        ))}

        {/* Divider before Guestbook */}
        <Divider sx={{ my: 1 }} />

        {/* Add Announcement button here */}
        <ListItem disablePadding>
          <ListItemButton
            onClick={handleAnnouncementClick}
            sx={{
              pl: 2,
              py: 1.5,
              color: theme.palette.mode === 'light' ? 'text.primary' : 'inherit',
              fontWeight: theme.palette.mode === 'light' ? 500 : 400,
              '&:hover': {
                color: 'primary.main',
                backgroundColor: alpha(theme.palette.primary.main, 0.08),
              },
            }}
          >
            <ListItemIcon>
              <CampaignIcon sx={{ color: 'inherit' }} />
            </ListItemIcon>
            <ListItemText 
              primary={t('common', 'announcement')}
              primaryTypographyProps={{
                sx: { fontWeight: 'inherit' }
              }}
            />
          </ListItemButton>
        </ListItem>

        {/* Guestbook */}
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/guestbook"
            sx={{
              pl: 2,
              color: theme.palette.mode === 'light' ? 'text.primary' : 'inherit',
              fontWeight: theme.palette.mode === 'light' ? 500 : 400,
              '&:hover': {
                color: 'primary.main',
                backgroundColor: alpha(theme.palette.primary.main, 0.08),
              },
            }}
          >
            <ListItemIcon>
              <ChatIcon sx={{ color: 'inherit' }} />
            </ListItemIcon>
            <ListItemText 
              primary={t('nav', 'guestbook')}
              primaryTypographyProps={{
                sx: { fontWeight: 'inherit' }
              }}
            />
          </ListItemButton>
        </ListItem>

        {renderMobileUserMenu()}

        {/* Divider before settings */}
        <Divider sx={{ my: 1 }} />

        {/* Language and Theme Settings */}
        <List>
          {/* Language Selection */}
          <ListItem disablePadding>
            <ListItemButton
              onClick={handleLangMenu}
              sx={{
                pl: 2,
                py: 1.5,
                color: theme.palette.mode === 'light' ? 'text.primary' : 'inherit',
              }}
            >
              <ListItemIcon>
                <TranslateIcon sx={{ color: 'inherit' }} />
              </ListItemIcon>
              <ListItemText 
                primary={t('common', 'language')}
                primaryTypographyProps={{
                  sx: { fontWeight: theme.palette.mode === 'light' ? 500 : 400 }
                }}
              />
            </ListItemButton>
          </ListItem>

          {/* Theme Toggle */}
          <ListItem disablePadding>
            <ListItemButton
              onClick={colorMode.toggleColorMode}
              sx={{
                pl: 2,
                py: 1.5,
                color: theme.palette.mode === 'light' ? 'text.primary' : 'inherit',
              }}
            >
              <ListItemIcon>
                {theme.palette.mode === 'dark' ? (
                  <Brightness7Icon sx={{ color: 'inherit' }} />
                ) : (
                  <Brightness4Icon sx={{ color: 'inherit' }} />
                )}
              </ListItemIcon>
              <ListItemText 
                primary={t('common', 'toggleTheme')}
                primaryTypographyProps={{
                  sx: { fontWeight: theme.palette.mode === 'light' ? 500 : 400 }
                }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </List>
    </Box>
  );

  const handleAnnouncementClick = () => {
    if (announcementRef?.current) {
      announcementRef.current.openAnnouncement();
    }
  };

  const renderDesktopUserMenu = () => (
    <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
      {isAuthenticated ? (
        <>
          <Button
            onClick={handleUserMenu}
            startIcon={<AccountCircleIcon />}
            {...commonButtonProps}
            sx={{
              ...commonButtonProps.sx,
              color: theme.palette.mode === 'light' ? 'text.primary' : 'text.navbar',
              fontWeight: theme.palette.mode === 'light' ? 500 : 400,
              '&:hover': {
                color: 'primary.main',
                backgroundColor: alpha(theme.palette.primary.main, 0.08),
              }
            }}
          >
            {user?.username || t('nav', 'memberPortal')}
          </Button>
          <Menu
            anchorEl={userMenuAnchor}
            open={Boolean(userMenuAnchor)}
            onClose={handleUserMenuClose}
            PaperProps={{
              elevation: 3,
              sx: {
                mt: 1.5,
                minWidth: 200,
                '& .MuiMenuItem-root': {
                  px: 2,
                  py: 1,
                  fontSize: '0.95rem',
                  '&:hover': {
                    backgroundColor: 'action.hover',
                  },
                },
              },
            }}
          >
            <MenuItem
              component={Link}
              to="/member"
              onClick={handleUserMenuClose}
            >
              <ListItemIcon>
                <AccountCircleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={t('nav', 'memberPortal')} secondary={user?.username} />
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={t('common', 'logout')} />
            </MenuItem>
          </Menu>
        </>
      ) : (
        <Button
          component={Link}
          to="/login"
          startIcon={<AccountCircleIcon />}
          {...commonButtonProps}
          sx={{
            ...commonButtonProps.sx,
            color: theme.palette.mode === 'light' ? 'text.primary' : 'text.navbar',
            fontWeight: theme.palette.mode === 'light' ? 500 : 400,
            '&:hover': {
              color: 'primary.main',
              backgroundColor: alpha(theme.palette.primary.main, 0.08),
            }
          }}
        >
          {t('nav', 'memberPortal')}
        </Button>
      )}
    </Box>
  );

  return (
    <>
      <AppBar 
        position="fixed" 
        elevation={0}
        sx={{ 
          bgcolor: 'background.paper',
          borderBottom: '1px solid',
          borderColor: 'divider',
          backdropFilter: 'blur(8px)',
          backgroundColor: alpha(theme.palette.background.paper, 0.95),
          boxShadow: theme.palette.mode === 'light' 
            ? '0 1px 3px rgba(0,0,0,0.12)'
            : 'none',
        }}
      >
        <Toolbar sx={{ 
          height: { xs: 56, md: 64 }, 
          px: { xs: 1, sm: 2, md: 3 },
          gap: { xs: 0.5, sm: 1, md: 2 },
        }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ 
              mr: { xs: 1, sm: 1, md: 2 },
              ml: { xs: 0.5 },
              display: { md: 'none' },
              color: 'text.primary',
              padding: { xs: '8px' },
              '&:hover': {
                backgroundColor: alpha(theme.palette.primary.main, 0.08),
              },
              '&:active': {
                backgroundColor: alpha(theme.palette.primary.main, 0.12),
              },
            }}
          >
            <MenuIcon sx={{ fontSize: '1.75rem' }} />
          </IconButton>

          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              flexGrow: { xs: 0, md: 0 },
              transition: 'transform 0.2s',
              '&:hover': {
                transform: 'scale(1.02)',
              },
            }}
          >
            <IconButton
              component={Link}
              to="/"
              size="small"
              sx={{ 
                p: 0.75,
                mr: { xs: 0.5, sm: 1 }, 
                color: 'primary.main',
                '&:hover': {
                  backgroundColor: 'transparent',
                  color: 'primary.dark',
                  transform: 'rotate(360deg)',
                  transition: 'transform 0.5s',
                },
              }}
            >
              <MovieIcon sx={{ 
                fontSize: { xs: '1.5rem', sm: '1.5rem' }
              }} />
            </IconButton>

            <Typography
              variant="h6"
              noWrap
              component={Link}
              to="/"
              sx={{
                fontFamily: "'Inter', sans-serif",
                fontWeight: 600,
                fontSize: { xs: '1rem', sm: '1.25rem' },
                letterSpacing: { xs: '.02rem', sm: '.05rem' },
                color: 'primary.main',
                textDecoration: 'none',
                textTransform: 'uppercase',
                display: 'block',
                '&:hover': {
                  color: 'primary.dark',
                },
              }}
            >
              247看
            </Typography>
          </Box>

          {renderDesktopCategories()}

          <Box sx={{ flexGrow: 1 }} />

          <Box sx={{ 
            display: 'flex',
            alignItems: 'center',
            gap: { xs: 0.5, sm: 1 },
          }}>
            <Search ref={searchRef}>
              <SearchIconWrapper>
                <SearchIcon sx={{ color: 'text.secondary' }} />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder={t('common', 'searchPlaceholder')}
                inputProps={{ 'aria-label': 'search' }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyPress={handleSearch}
                onFocus={() => setShowTrending(true)}
                sx={{
                  color: 'text.primary',
                  '&::placeholder': {
                    color: theme.palette.mode === 'light' 
                      ? 'rgba(0, 0, 0, 0.6)'
                      : 'rgba(255, 255, 255, 0.7)',
                    opacity: 0.7,
                  },
                  fontWeight: theme.palette.mode === 'light' ? 500 : 400,
                }}
              />
              <Box sx={{ 
                display: 'flex',
                alignItems: 'center',
                pr: 1,
              }}>
                <Box sx={{ transform: 'scale(1.1)' }}>
                  <HistoryWatched />
                </Box>
              </Box>
              
              {/* Trending Suggestions Dropdown */}
              {showTrending && (
                <Paper
                  elevation={3}
                  sx={{
                    position: 'absolute',
                    top: '100%',
                    left: 0,
                    right: 0,
                    mt: 1,
                    zIndex: 1000,
                    maxHeight: '400px',
                    overflow: 'auto',
                    borderRadius: 1,
                    backgroundColor: 'background.paper',
                    border: '1px solid',
                    borderColor: 'divider',
                  }}
                >
                  <List sx={{ py: 0 }}>
                    <ListItem sx={{ 
                      py: 1, 
                      px: 2,
                      borderBottom: '1px solid',
                      borderColor: 'divider',
                      bgcolor: 'action.hover'
                    }}>
                      <Typography variant="subtitle2" color="text.secondary">
                        {t('common', 'trending')}
                      </Typography>
                    </ListItem>
                    {trendingItems.map((item) => (
                      <ListItemButton
                        key={`${item.media_type}-${item.tmdb_id}`}
                        onClick={() => {
                          setSearchQuery(item.title || item.original_title);
                          navigate(`/search?q=${encodeURIComponent(item.title || item.original_title)}`);
                          setShowTrending(false);
                        }}
                        sx={{
                          py: 1.5,
                          '&:hover': {
                            bgcolor: 'action.hover',
                          },
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: 36 }}>
                          {item.media_type === 'movie' ? (
                            <LocalMoviesIcon color="primary" sx={{ fontSize: '1.2rem' }} />
                          ) : (
                            <LiveTvIcon color="primary" sx={{ fontSize: '1.2rem' }} />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={item.title || item.original_title}
                          primaryTypographyProps={{
                            variant: 'body2',
                            noWrap: true,
                          }}
                        />
                      </ListItemButton>
                    ))}
                  </List>
                </Paper>
              )}
            </Search>

            <Button
              component={Link}
              to="/guestbook"
              startIcon={<ChatIcon />}
              {...commonButtonProps}
              sx={{
                ...commonButtonProps.sx,
                display: { xs: 'none', md: 'flex' },
                color: theme.palette.mode === 'light' ? 'text.primary' : 'text.navbar',
                fontWeight: theme.palette.mode === 'light' ? 500 : 400,
                '&:hover': {
                  color: 'primary.main',
                  backgroundColor: alpha(theme.palette.primary.main, 0.08),
                },
              }}
            >
              {t('nav', 'guestbook')}
            </Button>

            {renderDesktopUserMenu()}

            <Stack 
              direction="row" 
              spacing={{ xs: 0.5, sm: 1 }}
              sx={{ 
                '& .MuiIconButton-root': {
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'scale(1.1)',
                    backgroundColor: alpha(theme.palette.primary.main, 0.08),
                  },
                },
              }}
            >
              <Tooltip title={t('common', 'announcement')}>
                <IconButton
                  onClick={handleAnnouncementClick}
                  size="small"
                  sx={{ 
                    color: 'text.primary',
                    display: { xs: 'none', sm: 'inline-flex' }
                  }}
                >
                  <CampaignIcon />
                </IconButton>
              </Tooltip>

              <IconButton
                onClick={handleLangMenu}
                size="small"
                sx={{ 
                  color: 'text.primary',
                  display: { xs: 'none', sm: 'inline-flex' }
                }}
              >
                <TranslateIcon />
              </IconButton>

              <IconButton
                size="small"
                sx={{ 
                  color: 'text.primary',
                  display: { xs: 'none', sm: 'inline-flex' }
                }}
                onClick={colorMode.toggleColorMode}
              >
                {theme.palette.mode === 'dark' ? (
                  <Brightness7Icon />
                ) : (
                  <Brightness4Icon />
                )}
              </IconButton>
            </Stack>
          </Box>
        </Toolbar>
      </AppBar>

      <Menu
        anchorEl={langAnchorEl}
        open={Boolean(langAnchorEl)}
        onClose={handleLangClose}
        PaperProps={{
          elevation: 3,
          sx: {
            mt: 1.5,
            minWidth: 120,
            '& .MuiMenuItem-root': {
              px: 2,
              py: 1,
              fontSize: '0.95rem',
              '&:hover': {
                backgroundColor: 'action.hover',
              },
            },
          },
        }}
      >
        {availableLanguages.map((lang) => (
          <MenuItem
            key={lang.code}
            onClick={() => {
              setLanguage(lang.code);
              handleLangClose();
            }}
            selected={language === lang.code}
          >
            {lang.name}
          </MenuItem>
        ))}
      </Menu>

      <Drawer
        variant="temporary"
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { 
            boxSizing: 'border-box',
            width: 240,
            bgcolor: 'background.paper'
          },
        }}
      >
        {renderMobileMenu()}
      </Drawer>
    </>
  );
};

export default Navbar; 