import React, { useRef } from 'react';
import { Box, useTheme, useScrollTrigger, Fade, Backdrop, CircularProgress, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import AnnouncementOverlay from './AnnouncementOverlay';

const Layout = ({ children }) => {
  const location = useLocation();
  const theme = useTheme();
  const [loading, setLoading] = React.useState(true);
  const announcementRef = useRef();
  
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  React.useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, [location.pathname]);

  const isWatchPage = location.pathname.startsWith('/watch');

  return (
    <Box 
      sx={{ 
        minHeight: '100vh',
        bgcolor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        transition: theme.transitions.create('background-color', {
          duration: theme.transitions.duration.standard,
        }),
      }}
    >
      <Navbar scrolled={trigger} announcementRef={announcementRef} />
      
      <Backdrop
        sx={{ 
          color: 'primary.main',
          zIndex: theme.zIndex.drawer + 1,
          backdropFilter: 'blur(4px)',
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Fade in={!loading} timeout={500}>
        <Box 
          component="main" 
          sx={{ 
            flex: 1,
            position: 'relative',
            width: '100%',
            maxWidth: theme.breakpoints.values.xl,
            mx: 'auto',
            ...(isWatchPage && {
              height: '100vh',
              overflow: 'hidden'
            }),
            '& > *': {
              transition: theme.transitions.create(['opacity'], {
                duration: theme.transitions.duration.standard,
              }),
            }
          }}
        >
          {children}
        </Box>
      </Fade>
      
      <Footer />
      <AnnouncementOverlay 
        ref={announcementRef}
        announcement={
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Box>
              <Typography variant="h5" sx={{ color: 'primary.main', fontWeight: 700, mb: 2 }}>
                欢迎来到247看 247kan.com
              </Typography>
              <Typography variant="body1" sx={{ color: 'text.primary', lineHeight: 1.6 }}>
                感谢您选择247看作为您的观影平台！我们致力于为您提供最佳的观影体验。
              </Typography>
            </Box>

            <Box>
              <Typography variant="h6" sx={{ color: 'primary.main', fontWeight: 600, mb: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
                📱 功能亮点
              </Typography>
              <Typography variant="body1" component="div" sx={{ color: 'text.primary', pl: 2 }}>
                • 完全免费无广告：提供最纯净的观影<br/>
                • 全新会员系统：支持收藏、观看历史等功能<br/>
                • 智能投屏：支持 AirPlay、Chromecast、Miracast<br/>
                • 高清视频：支持1080P视频播放<br/>
                • 智能去广告：采用先进算法，提供清爽观影体验
              </Typography>
            </Box>

            <Box>
              <Typography variant="h6" sx={{ color: 'primary.main', fontWeight: 600, mb: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
                ⚡ 使用提示
              </Typography>
              <Typography variant="body1" component="div" sx={{ color: 'text.primary', pl: 2 }}>
                • 投屏功能需要设备在同一网络下使用<br/>
                • 电视端投屏暂不支持去广告和弹幕功能<br/>
                • Chrome浏览器可获得最佳观影体验
              </Typography>
            </Box>

            <Box>
              <Typography variant="h6" sx={{ color: 'primary.main', fontWeight: 600, mb: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
                🚀 未来规划
              </Typography>
              <Typography variant="body1" sx={{ color: 'text.primary', pl: 2 }}>
                有意开发247看的移动应用，将支持安卓、iOS和智能电视平台。但会先确保网页版的稳定性和完善度，再逐步推进应用开发。
              </Typography>
            </Box>

            <Typography variant="body1" sx={{ color: 'text.secondary', mt: 1, fontStyle: 'italic' }}>
              作为一个非营利项目，我将持续优化和维护。欢迎在留言板分享您的观影体验和建议。祝您观影愉快！
            </Typography>
          </Box>
        }
      />
    </Box>
  );
};

export default Layout; 