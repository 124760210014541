import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Box,
  useTheme,
  alpha,
  Divider,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import UpdateIcon from '@mui/icons-material/Update';
import { useLanguage } from '../contexts/LanguageContext';

// Simple hash function for announcement content
const hashString = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash;
  }
  return hash.toString();
};

const updateLogs = [
  {
    date: '2024/12/13',
    title: '功能优化与界面更新',
    updates: [
      '优化浏览页面的筛选面板，提供更直观的操作体验',
      '修复弹幕系统，确保弹幕正确发送和显示',
      '更新智能去广告插件，提升广告识别准确率',
      '优化验证码功能，提供更流畅的用户验证体验',
      '新增自动/手动播放下集功能，支持连续观看',
      '重新设计公告和更新日志界面，提供更好的阅读体验',
      '修复搜索结果显示问题和翻译问题'
    ]
  },
  {
    date: '2024/12/10',
    title: '重大功能更新',
    updates: [
      '推出会员系统：支持收藏、稍后观看、观看历史等功能',
      '优化去广告算法：显著提升准确率，减少误判情况',
      '升级搜索引擎：改进搜索算法，提供更准确的搜索结果',
      '新增互动弹幕：支持实时互动，Chrome浏览器可获得最佳体验',
      '支持多种投屏：新增 AirPlay、Chromecast、Miracast 投屏功能'
    ]
  }
];

const AnnouncementOverlay = ({ announcement }, ref) => {
  const theme = useTheme();
  const { t } = useLanguage();
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('announcement');

  useEffect(() => {
    // Get the current announcement hash
    const currentHash = hashString(announcement?.props?.children?.toString() || '');
    
    // Get the stored hash from localStorage
    const storedHash = localStorage.getItem('announcementHash');
    
    // Show announcement if the hash is different (content changed) or never shown before
    if (currentHash !== storedHash) {
      setOpen(true);
      localStorage.setItem('announcementHash', currentHash);
    }
  }, [announcement]);

  // Add this function to expose the open method
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Add this to expose the open method through a ref
  useImperativeHandle(ref, () => ({
    openAnnouncement: handleOpen
  }));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          bgcolor: 'background.paper',
          backgroundImage: 'none',
          borderRadius: 3,
          boxShadow: theme.shadows[4],
          overflow: 'hidden',
        },
      }}
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          bgcolor: alpha(theme.palette.primary.main, 0.08),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography
            variant="h6"
            component="span"
            sx={{
              fontWeight: 700,
              color: 'text.primary',
              fontSize: { xs: '1.1rem', sm: '1.25rem' },
              cursor: 'pointer',
              borderBottom: activeTab === 'announcement' ? `2px solid ${theme.palette.primary.main}` : 'none',
              pb: 0.5,
            }}
            onClick={() => setActiveTab('announcement')}
          >
            {t('common', 'announcement')}
          </Typography>
          <Typography
            variant="h6"
            component="span"
            sx={{
              fontWeight: 700,
              color: 'text.primary',
              fontSize: { xs: '1.1rem', sm: '1.25rem' },
              cursor: 'pointer',
              borderBottom: activeTab === 'updates' ? `2px solid ${theme.palette.primary.main}` : 'none',
              pb: 0.5,
            }}
            onClick={() => setActiveTab('updates')}
          >
            更新日志
          </Typography>
        </Box>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            color: 'text.secondary',
            '&:hover': {
              color: 'text.primary',
              bgcolor: alpha(theme.palette.primary.main, 0.12),
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          p: { xs: 2, sm: 3 },
          '&:first-of-type': {
            pt: { xs: 2, sm: 3 },
          },
        }}
      >
        {activeTab === 'announcement' ? (
          <Box
            sx={{
              '& > *:not(:last-child)': {
                mb: 2,
              },
            }}
          >
            {announcement}
          </Box>
        ) : (
          <List sx={{ width: '100%', p: 0 }}>
            {updateLogs.map((log, index) => (
              <React.Fragment key={log.date}>
                <ListItem
                  alignItems="flex-start"
                  sx={{
                    px: 0,
                    pt: index === 0 ? 0 : 3,
                    pb: 3,
                    display: 'block',
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: 'text.secondary',
                        letterSpacing: 0.5,
                        mb: 1
                      }}
                    >
                      {log.date}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        color: 'text.primary',
                        fontWeight: 600,
                        fontSize: '1.1rem',
                        mb: 2
                      }}
                    >
                      {log.title}
                    </Typography>
                  </Box>
                  <List sx={{ 
                    pl: 2,
                    '& .MuiListItem-root': {
                      transition: 'all 0.2s ease-in-out',
                      borderLeft: `2px solid ${alpha(theme.palette.primary.main, 0.2)}`,
                      '&:hover': {
                        borderLeft: `2px solid ${theme.palette.primary.main}`,
                        bgcolor: alpha(theme.palette.primary.main, 0.04),
                      }
                    }
                  }}>
                    {log.updates.map((update, i) => (
                      <ListItem 
                        key={i} 
                        sx={{ 
                          px: 2, 
                          py: 1,
                          '&:hover': {
                            '& .MuiListItemText-primary': {
                              color: 'primary.main',
                            }
                          }
                        }}
                      >
                        <ListItemText
                          primary={update}
                          sx={{
                            '& .MuiListItemText-primary': {
                              fontSize: '0.95rem',
                              color: 'text.primary',
                              lineHeight: 1.6,
                              transition: 'color 0.2s ease-in-out',
                            },
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </ListItem>
                {index < updateLogs.length - 1 && (
                  <Divider />
                )}
              </React.Fragment>
            ))}
          </List>
        )}
      </DialogContent>
    </Dialog>
  );
};

// Export with forwardRef
export default forwardRef(AnnouncementOverlay); 