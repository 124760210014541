// Common and memorable Chinese characters for adjectives/descriptions
const descriptors = ['小', '大', '快', '慢', '酷', '萌', '笑', '闪', '飞', '酸'];

// Fun nouns that are easy to remember
const nouns = ['猫', '狗', '兔', '熊', '鱼', '鸟', '龙', '虎', '猪', '鼠'];

export const generateAnonymousName = () => {
  // Get random indices based on timestamp to make it deterministic
  const timestamp = Date.now().toString();
  const lastTwoDigits = timestamp.slice(-2);
  const num = parseInt(lastTwoDigits, 10);
  
  // Select characters
  const descriptor = descriptors[num % descriptors.length];
  const noun = nouns[Math.floor(num / 10) % nouns.length];
  
  // Add a number to make it unique (last digit of timestamp)
  const uniqueNum = timestamp.slice(-1);
  
  return `${descriptor}${noun}${uniqueNum}号`;
}; 